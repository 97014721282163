import type React from 'react';
import { useContext } from 'react';
import type { ControllerProps } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';

import { InputErrorMessage } from '@/components/uikit/Input/PrivateComponents/InputErrorMessage';
import { EMPTY_ERR_MSG } from '@/components/uikit/Input/PrivateComponents/TdFormControl';
import type { TdFormInputProps } from '@/components/uikit/Input/TdInput';
import { FormInputCtx } from '@/components/uikit/Input/TdInput';
import RenderIf from '@/shared/render-if/RenderIf';
import type { SignUpFormData } from '@/types/signup.types';

import tdInputBoxStyles from './TdInputBoxStyles';

type TdInputBoxProps = {
  CustomErrorMsg?: React.ReactNode;
} & TdFormInputProps & Omit<ControllerProps<SignUpFormData>, 'name' | 'defaultValue'>

export const TdInputBox: React.FC<TdInputBoxProps> = ({ CustomErrorMsg, ...allProps }) => {
  const { watch, control, formState: { errors }} = useFormContext<SignUpFormData>();
  const { touchedFields } = useContext(FormInputCtx);
  const isTouched = touchedFields[allProps.variety];
  const hasError = isTouched && !!errors[allProps.variety]?.message;
  const isValid = !!watch(allProps.variety) && errors[allProps.variety]?.message == undefined;

  return (
    <Box
      css={ tdInputBoxStyles.inputBoxRoot }
      data-has-error={ hasError }
      data-is-valid={ isValid }
      data-is-neutral={ !hasError && !isValid }
    >
      <InputLabel css={ tdInputBoxStyles.inputLabel } htmlFor={ allProps.inputId }>{ allProps.label }</InputLabel>
      <Controller
        defaultValue=""
        name={ allProps.variety }
        control={ control }
        render={ allProps.render }
      />
      <RenderIf condition={ allProps.variety !== 'password' }>
        <InputErrorMessage id={ `err-${ allProps.variety }` }>
          { CustomErrorMsg || (isTouched && errors[allProps.variety]?.message) || EMPTY_ERR_MSG }
        </InputErrorMessage>
      </RenderIf>
    </Box>
  );
};
