import type { FC } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { css, Global } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { useTrackPage } from '@/hooks';
import { Layout } from '@/layouts/Layout';
import type { DefaultPageStoryblok } from '@/sb-types';

import { page } from '../../../redux/features/trackingSlice';
import { web3Theme } from '../../../theme';

type DefaultPageBlokProps = {
  blok: SbBlokData<DefaultPageStoryblok>;
}

const globalStyles = css`
  html {
    scroll-behavior: smooth;
  }
`;

let idCounter = 0;
const generateKey = (prefix = 'key') => {
  idCounter += 1;
  return `${ prefix }_${ idCounter }`;
}

const styles = {
  Footer: css`
    padding: 0;
    margin: 0;
    @media (max-width: 1024px) {
      padding: 0;
    }
  `,
}

const DefaultPageBlok: FC<DefaultPageBlokProps> = ({ blok }) => {
  useTrackPage({ ...blok });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(page(blok.trackingName) || '');
  }, [blok.trackingName]);

  return (
    <>
      <Global styles={ globalStyles } />
      { blok?.header?.map(nestedBlok => (
        <StoryblokComponent blok={ nestedBlok } key={ generateKey(nestedBlok._uid) } />
      )) }
      <Layout
        pageName={ blok.trackingName } pageCategory={ blok.pageCategory } pageTitle={ blok.meta?.title }
        pageDesc={ blok.meta?.description } header={ blok.header } footer={ blok.footer }
        withFooter={ blok.withFooter } withHeader={ blok.withHeader } { ...storyblokEditable(blok) }>
        <ThemeProvider theme={ web3Theme }>
          { blok?.body?.map(nestedBlok => (
            <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />
          )) }
        </ThemeProvider>
      </Layout>
      <footer css={ styles.Footer }>
        { blok?.footer?.map(nestedBlok => (
          <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />
        )) }
      </footer>
    </>
  );
};

export default DefaultPageBlok;
