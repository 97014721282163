import axios from 'axios';

import type { TrackApiPayload } from '../types';

const DEFAULT_API_URL = 'https://data-integrity.timedoctor.com/api/v1/tracking';

export function dataIntegrityCall(payload: TrackApiPayload) {
  const apiUrl = process.env.NEXT_PUBLIC_DATA_INTEGRITY_URL || DEFAULT_API_URL;
  const apiKey = process.env.NEXT_PUBLIC_DATA_INTEGRITY_KEY || '';

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': apiKey,
  };

  return axios.post(apiUrl, payload, { headers });
}
