

export const NeverBounceWidget: NeverbounceConfig = {
  inputLatency: 1000,
  feedback: false,
  displayPoweredBy: false,
  timeout: 6,
  blockFreemail: false,
  apiKey: process.env.NEXT_PUBLIC_NEVERBOUNCE_KEY,
  blockRoleAccount: false,
  blockThrottledAttempts: false,
  apiOnly: false,
  autoFieldHookup: false,
};
