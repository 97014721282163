import { css } from '@emotion/react';
import type { Theme } from '@mui/material/styles';

import whitePaperBg from '../../../../../public/images/white-paper-bg.png';


const imgStyles = (theme: Theme) => css`
  position: absolute;
  right: 0;
  bottom: -10px;
  margin: 0;

  ${ theme.breakpoints.down('md') } {
    bottom: -32px;
  }
`;

export const styles = {
  Root: (theme: Theme) => css`
    --img-lg-right: 20%;

    overflow: hidden;
    position: relative;
    border-radius: 0 25px 25px;

    & .RichText-paragraph {
      color: #fff;
    }

    ${ theme.breakpoints.down('md') } {
      --img-lg-right: 35%;
    }
  `,
  Card: (theme: Theme) => css`
    background: linear-gradient(90deg, #2c2e3c 0%, rgba(76, 77, 94, 0) 71.82%) 100% 0 / 60% 100% no-repeat, 100% 0 / 60% 105% no-repeat url(${ whitePaperBg.src }), ${ theme.palette.info.main };
    background-blend-mode: luminosity;

    & .Card-content {
      padding: 64px 0 0 64px;
      min-height: 490px;

      & .RichText-paragraph {
        margin-top: 0;
      }

      ${ theme.breakpoints.down('lg') } {
        padding: 32px 0 0 32px;
        min-height: unset;
      }

      ${ theme.breakpoints.down('md') } {
        padding-right: 32px;
      }
    }

    ${ theme.breakpoints.down('md') } {
      background: ${ theme.palette.info.main };
    }
  `,
  FadedImgWrapper: (theme: Theme) => css`
    ${ imgStyles(theme) };

    right: 100px;
    transform: rotate(8.5deg);
    margin: 0;
    filter: blur(1px);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 50px rgba(44, 46, 60, 0.15);
      background: rgba(220, 221, 225, 0.5);
    }

    ${ theme.breakpoints.down('xl') } {
      right: calc(var(--img-lg-right) - 40px);
    }

    ${ theme.breakpoints.down('md') } {
      right: -24px;
      left: 0;
      margin: 0 auto;
      width: 250px;
    }
  `,
  NormalImg: (theme: Theme) => css`
    ${ imgStyles(theme) };

    right: 168px;

    ${ theme.breakpoints.down('xl') } {
      right: var(--img-lg-right);
    }

    ${ theme.breakpoints.down('md') } {
      right: 56px;
      left: 0;
      margin: 0 auto;
    }
  `,
};
