import { useEffect } from 'react';

import { useAppDispatch } from '@/hooks';

import { analyticsPage, pageInfo } from '../../redux/features/trackingSlice';


type UseTrackPageArgs = {
  pageName: string;
  pageCategory: string;
}

export const useTrackPage = ({ pageName, pageCategory }: UseTrackPageArgs) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(pageInfo({
      name: pageName,
      category: pageCategory,
    }));

    dispatch(analyticsPage({
      name: pageName,
      category: pageCategory,
      properties: {
        name: pageName,
        category: pageCategory,
      },
    }));
  }, []);
};
