import type { FC } from 'react';
import styled from '@emotion/styled';
import MuiGrid from '@mui/material/Unstable_Grid2';
import type { GridSize } from '@mui/system';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import type { GridItemStoryblok } from '@/sb-types';


type GridItemBlokProps = {
  blok: SbBlokData<GridItemStoryblok>
}

type UserStyles = Pick<GridItemStoryblok, 'config'>;

const StyledMuiGrid = styled(MuiGrid)<UserStyles>(({ config, theme }) => ({
  ...config,
  ...theme.utils.objStyleBreakpoints(config),
}));

export const GridItemBlok: FC<GridItemBlokProps> = ({ blok }) => (
  <StyledMuiGrid
    zero={ getColumnSpan(blok.zeroSpan) } sm={ getColumnSpan(blok.smSpan) }
    md={ getColumnSpan(blok.mdSpan) } lg={ getColumnSpan(blok.lgSpan) }
    xl={ getColumnSpan(blok.xlSpan) }
    { ...blok }
    { ...storyblokEditable(blok) }
  >
    {
      blok.content?.map(itemBlok => <StoryblokComponent blok={ itemBlok } key={ itemBlok._uid } />)
    }
  </StyledMuiGrid>
);

const getColumnSpan = (span: string | undefined): GridSize | undefined => {
  if (!span) return undefined;
  return span === 'auto' ? 'auto' : +span;
}
