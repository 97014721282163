import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { CardStoryblok } from '@/sb-types';

import { renderRichText } from '../../../storyblok/render-rich-text';


type CardBlokProps = {
  blok: SbBlokData<CardStoryblok>;
}

const Card = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})<CardStoryblok>(
  ({ bgColor, paddingObj, marginObj, sizeObj, borderRadiusObj, theme, boxShadow }) => ({
    padding: theme.spacing(3),
    maxWidth: '300px',
    boxShadow: boxShadow || theme.shadows[1],
    background: '#fff',
    borderRadius: '0 25px 25px 25px',

    ...bgColor,
    ...paddingObj,
    ...marginObj,
    ...sizeObj,
    ...borderRadiusObj,

    ...theme.utils.objStyleBreakpoints(bgColor, paddingObj, marginObj, sizeObj, borderRadiusObj),
  }),
);

const CardContent = styled.div<CardStoryblok>(({ theme, contentConfig, contentPadding }) => ({
  height: '100%',
  ...contentConfig,
  ...contentPadding,

  ...theme.utils.objStyleBreakpoints(contentConfig, contentPadding),
}));

export const CardBlok: FC<CardBlokProps> = ({ blok }) => (
  <Card { ...blok } { ...storyblokEditable(blok) }>
    <CardContent { ...blok }>
      { renderRichText(blok.content) }
    </CardContent>
  </Card>
);
