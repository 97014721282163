import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MuiTypography from '@mui/material/Typography';

import { styles } from '@/components/uikit/AccordionA11y/AccordionA11y.styles';
import { useScreenResize } from '@/hooks';
import RenderIf from '@/shared/render-if/RenderIf';


type Props = {
  accordionId: string;
  headerTitle: string;
  headingLvl: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  iconName?: string;
  iconPosition?: 'before' | 'after';
  children?: ReactNode;
  variant?: 'compact' | 'large';
  isExpanded?: boolean;
}

type HeadingVariantProps = {
  toggleAccordion: () => void;
} & Pick<Props, 'accordionId' | 'headerTitle' | 'iconPosition' | 'variant'>

export const AccordionA11y: FC<Props> = (
  {
    accordionId,
    headerTitle,
    headingLvl,
    children,
    iconPosition = 'before',
    variant = 'compact',
    isExpanded = false,
    ...restProps
  },
) => {

  const { width } = useScreenResize();
  const accordionGrpRef = useRef<HTMLDivElement>(null);
  const accordionContentElmRef = useRef<HTMLDivElement>();

  const accordionContentRef = useCallback((node: HTMLDivElement) => {
    if (node != null) {
      accordionContentElmRef.current = node;
      overrideAnchorsTabIndex(-1);
    }
  }, [])

  useEffect(() => {
    const accordionContentHeight = accordionContentElmRef?.current?.hasAttribute('aria-hidden')
      ? 0
      : getAccordionContentHeight();

    if (accordionGrpRef?.current) {
      const accordionCollapsedHeight = accordionGrpRef.current.firstElementChild?.clientHeight || 0;
      accordionGrpRef.current.style.height = `${ accordionCollapsedHeight + accordionContentHeight }px`;
    }
  }, [width]);


  useEffect(() => {
    // Ensure the accordion group ref and content element ref are available
    if (!accordionGrpRef.current || !accordionContentElmRef.current) return;

    const accordionHeadingElm = accordionGrpRef.current.querySelector('button') as HTMLElement;
    const accordionContentElm = accordionContentElmRef.current;

    // Check if the accordion is already in the desired state to avoid unnecessary updates
    const isCurrentlyExpanded = accordionHeadingElm.getAttribute('aria-expanded') === 'true';

    if (isExpanded && !isCurrentlyExpanded) {
      expandAccordion(accordionHeadingElm, accordionContentElm);
    } else if (!isExpanded && isCurrentlyExpanded) {
      collapseAccordion(accordionContentElm, accordionHeadingElm);
    }
  }, [isExpanded]);

  function overrideAnchorsTabIndex(tabIndex: 0 | -1) {
    const links = accordionContentElmRef?.current?.querySelectorAll('a');

    if (links) {
      links.forEach((link) => {
        link.setAttribute('tabIndex', `${ tabIndex }`);
      });
    }
  }

  function expandAccordion(accordionHeadingElm: HTMLElement, accordionContentElm: HTMLElement): void {
    accordionContentElm.removeAttribute('aria-hidden');
    accordionHeadingElm.setAttribute('aria-expanded', 'true');

    if (accordionGrpRef?.current) {
      const accordionCollapsedHeight = accordionGrpRef.current.firstElementChild?.clientHeight || 0;
      accordionGrpRef.current.style.height = `${ accordionCollapsedHeight + accordionContentElm.clientHeight }px`;
      accordionGrpRef.current.dataset['expanded'] = 'true';
      overrideAnchorsTabIndex(0);
    }
  }

  function collapseAccordion(
    accordionContentElm: HTMLDivElement,
    accordionHeadingElm: HTMLElement,
  ): void {
    accordionContentElm.setAttribute('aria-hidden', 'true');
    accordionHeadingElm.setAttribute('aria-expanded', 'false');

    if (accordionGrpRef?.current) {
      const accordionCollapsedHeight = accordionGrpRef.current.firstElementChild?.clientHeight;
      accordionGrpRef.current.style.height = `${ accordionCollapsedHeight }px`;
      accordionGrpRef.current.dataset['expanded'] = 'false';
      overrideAnchorsTabIndex(-1);
    }
  }

  function toggleAccordion() {
    if (!accordionContentElmRef?.current) return;
    const accordionContentElm = accordionContentElmRef.current;

    const accordionHeadingElm = accordionGrpRef.current?.querySelector('button') as HTMLElement;
    if (!accordionHeadingElm) return;
    const expandedAttr = accordionHeadingElm.getAttribute('aria-expanded');

    if (expandedAttr === 'false') {
      expandAccordion(accordionHeadingElm, accordionContentElm);
    } else {
      collapseAccordion(accordionContentElm, accordionHeadingElm);
    }
  }

  function getAccordionContentHeight() {
    const accordionContentElm = accordionContentElmRef?.current;

    return accordionContentElm ? accordionContentElm.offsetHeight : 0;
  }

  return (
    <div
      id={ `accordion-group-${ accordionId }` }
      ref={ accordionGrpRef }
      css={ styles.Root }
      data-testid="accordion-group"
      data-expanded="false"
      data-variant={ variant }
      { ...restProps }
    >

      <MuiTypography
        css={ styles.Header }
        variant={ headingLvl }
        data-icon-position={ iconPosition }
      >
        <HeadingVariant { ...{ accordionId, iconPosition, headerTitle, variant, toggleAccordion } } />
      </MuiTypography>

      <div
        id={ `${ accordionId }-content` }
        ref={ accordionContentRef }
        css={ styles.Content }
        role="region"
        aria-labelledby={ accordionId }
        aria-hidden
      >
        <div>
          { children }
        </div>
      </div>
    </div>
  );
};

const HeadingVariant: FC<HeadingVariantProps> = ({
  accordionId,
  headerTitle,
  variant,
  toggleAccordion,
}) => (
  <>
    <RenderIf condition={ variant === 'large' }>
      <button
        data-testid="answerId"
        id={ accordionId }
        css={ styles.Trigger }
        aria-controls={ `${ accordionId }-content` }
        aria-expanded="false"
        onClick={ toggleAccordion }
      >
        <span css={ styles.Title }>
          { headerTitle }
        </span>
        <div css={ styles.PlusIcon }>
          <span css={ styles.PlusIcon__Line } />
          <span css={ styles.PlusIcon__Line } />
        </div>
      </button>
    </RenderIf>

    <RenderIf condition={ variant === 'compact' }>
      <button
        type="button"
        id={ accordionId }
        css={ styles.Trigger }
        aria-expanded="false"
        aria-controls={ `${ accordionId }-content` }
        onClick={ toggleAccordion }
      >
        <span css={ styles.Title }>
          { headerTitle }
        </span>
        <ExpandMore
          css={ styles.Icon }
          color="secondary" sx={{ fontSize: 24 }}
        />
      </button>
    </RenderIf>
  </>
);
