import type { FC } from 'react';

import { getMainDomain } from '@/utils/cookies.util';

export const InviteOnlyDomainMsg: FC<{ email: string }> = ({ email }) => {
  const signInUrl = `https://2.${ getMainDomain() }/login?email=${ email }`;
  return (
    <>
      Your company already has a Time Doctor account. Please request to be invited to the existing account, or accept your invitation if you&apos;ve already been invited. Would you like to&nbsp;
      <a
        href={ signInUrl }
        data-testid="error-sign-in-link"
        className="sign-in-link link"
      >
        Sign In
      </a>
      &nbsp;instead?
    </>
  );
};
