import type { LoadingButtonProps } from '@mui/lab/LoadingButton';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

import type AdditionalButtonProps from '@/components/uikit/button/TdButton';
import { skipPropForwardingFn } from '@/components/uikit/button/TdButton';

export type TdLoadingButtonStyle = 'default' | 'legacy';

type AdditionalButtonProps = {
  btnStyle?: TdLoadingButtonStyle;

  /** Defines the corner that won't the border radius. By default, this is `bottomLeft` */
  noBorderCorner?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'none';

  /** Determines if the button will have rounder corners. By default, this is false */
  rounded?: boolean;

  /** Determines if the button will have all text in caps. By default, this is false */
  uppercase?: boolean;
}

type TdLoadingButtonProps = AdditionalButtonProps & LoadingButtonProps

const TdLoadingButton = styled(LoadingButton, {
  shouldForwardProp: prop => skipPropForwardingFn<AdditionalButtonProps>(['rounded', 'uppercase', 'noBorderCorner', 'btnStyle'], prop),
})<TdLoadingButtonProps>((props) => ({
  textTransform: props.uppercase ? 'uppercase' : 'none',
  ...(props.rounded ? { borderRadius: '26px' } : { borderRadius: '12px' }),
  ...(props.noBorderCorner === 'bottomLeft' && { borderBottomLeftRadius: 0 }),
  ...(props.noBorderCorner === 'bottomRight' && { borderBottomRightRadius: 0 }),
  ...(props.noBorderCorner === 'topLeft' && { borderTopLeftRadius: 0 }),
  ...(props.noBorderCorner === 'topRight' && { borderTopRightRadius: 0 }),
  ...(props.btnStyle === 'default' && {
    fontSize: '16px',
    padding: '10px 22px',
    fontWeight: 400,
  }),
}));

export default TdLoadingButton;
