import axios from 'axios';

const releasedVersionUrls: Record<AllowedOS, string> = {
  windows: process.env.NEXT_PUBLIC_WINDOWS_BUILD_VERSION || 'https://s3.amazonaws.com/sfproc-downloads/released-win-v3.json',
  macos: process.env.NEXT_PUBLIC_MACOS_BUILD_VERSION || 'https://s3.amazonaws.com/sfproc-downloads/released-win-v3.json',
  ubuntu: process.env.NEXT_PUBLIC_UBUNTU_BUILD_VERSION || 'https://s3.amazonaws.com/sfproc-downloads/released-win-v3.json',
};

const VERSION = '3.9.91';

export const buildInfo: DownloadInfoType = {
  windows: {
    name: 'Windows',
    version: VERSION,
    url: `https://s3.amazonaws.com/sfproc-downloads/${ VERSION }/windows/bitrock/timedoctor2-setup-${ VERSION }-windows.exe`,
  },
  macos: {
    name: 'Mac Os',
    version: VERSION,
    url: `https://s3.amazonaws.com/sfproc-downloads/${ VERSION }/macos/dmg/timedoctor2-${ VERSION }-macos.dmg`,
  },
  ubuntu: {
    name: 'Ubuntu',
    version: VERSION,
    url: `https://s3.amazonaws.com/sfproc-downloads/${ VERSION }/linux/ubuntu-18.04/interactive/timedoctor2-setup-${ VERSION }-linux-x86_64.run`,
  },
};

type LatestSupportedOsVersion = {
  [key in AllowedOS]: string;
};

type UserAgentDict = {
  [key: string]: string;
};

export const latestSupportedOsVersion: LatestSupportedOsVersion = {
  windows: 'Windows 10',
  macos: 'OS X Mojave',
  ubuntu: 'Ubuntu 20.04 LTS',
}

const userAgentDict: UserAgentDict = {
  'mobi': 'Mobile',
  'android': 'Mobile',
  'webos': 'Mobile',
  'iphone': 'Mobile',
  'ipad': 'Mobile',
  'blackberry': 'Mobile',
  'windows phone': 'Mobile',
  'opera mini': 'Mobile',
  'iemobile': 'Mobile',
  'windows': 'Desktop',
  'macintosh': 'Desktop',
  'macos': 'Desktop',
  'ubuntu': 'Desktop',
  'linux': 'Desktop',
  'ipod': 'Mobile',
};

const desktopOSKeys: Record<string, AllowedOS> = {
  'macintosh': 'macos',
  'linux': 'ubuntu',
};

export const isMobileOS = (os: string): boolean => os ? userAgentDict[os] === 'Mobile' : false;
export const isDesktopOS = (os: string): boolean => os ? userAgentDict[os] === 'Desktop' : false;

export const detectOS = (userAgentString?: string | undefined) => {
  const userAgent = (userAgentString ?? window.navigator.userAgent).toLowerCase();
  const foundKey = Object.keys(userAgentDict).find(key => userAgent.includes(key));
  return foundKey ? (desktopOSKeys[foundKey] ?? foundKey ) : 'unknown';
}

export async function fetchTdAppReleaseVersion(): Promise<DownloadInfoType> {
  try {
    const fetchPromises = Object.entries(releasedVersionUrls).map(async ([os, url]) => {
      const response = await axios.get<TDAppReleaseVersion>(url);
      const version = response.data['cloud-download-page'] ?? response.data.cloud;

      if (!buildInfo[os as AllowedOS]) {
        throw new Error(`Invalid OS type: ${ os }`);
      }

      return {
        [os]: {
          name: buildInfo[os as AllowedOS].name,
          version,
          url: buildInfo[os as AllowedOS].url.replace(new RegExp(VERSION, 'g'), version),
        },
      };
    });

    const fetchedDataArray = await Promise.all(fetchPromises);
    const fetchedCloudDownloadPages = Object.assign({}, ...fetchedDataArray);

    return fetchedCloudDownloadPages;

  } catch (error) {
    return buildInfo;
  }
}

export function getDownloadUrlByOsAndVersion(os: AllowedOS, version: string): string | undefined {
  return os in buildInfo ? buildInfo[os].url.replace(new RegExp(VERSION, 'g'), version) : undefined;
}

