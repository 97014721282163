import type { ReactNode } from 'react';
import Head from 'next/head';

import { useBaseLayoutState } from './BaseLayout.state';


export interface BaseLayoutProps {
  pageTitle: string;
  pageDesc: string;
  pageCategory?: string;
  children?: ReactNode;

  keywords?: string;
}

export const BaseLayout = ({
  children,
  keywords = '',
  pageDesc,
  pageTitle,
}: BaseLayoutProps) => {
  useBaseLayoutState();

  return (
    <>
      <Head>
        <title data-testid="head-title">{ pageTitle }</title>
        <meta name="description" content={ pageDesc } />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta name="keywords" content={ keywords } />
      </Head>
      <>{ children }</>
    </>
  );
};
