import type React from 'react';
import { useContext } from 'react';
import type { ControllerProps } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import type { InputBaseProps } from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import type { SxProps } from '@mui/system';

import { InputErrorMessage } from '@/components/uikit/Input/PrivateComponents/InputErrorMessage';
import type { TdFormInputProps, TdInputProps } from '@/components/uikit/Input/TdInput';
import { FormInputCtx } from '@/components/uikit/Input/TdInput';
import TdTooltip from '@/components/uikit/tooltip/TdTooltip';
import { useScreenResize } from '@/hooks';
import RenderIf from '@/shared/render-if/RenderIf';
import type { SignUpFormData } from '@/types/signup.types';

type TdFormControl = {
  CustomErrorMsg?: React.ReactNode;
  customErrCond?: boolean;
} & TdFormInputProps & Omit<ControllerProps<SignUpFormData>, 'name' | 'defaultValue'>

const ERR_MSG_BREAKPOINT = 1023;
export const EMPTY_ERR_MSG = ' ';

export const TdFormControl: React.FC<TdFormControl> = (
  {
    CustomErrorMsg,
    customErrCond,
    variant = 'filled',
    fullWidth = true,
    rounded,
    ...allProps
  },
) => {
  const { control, formState: { errors }} = useFormContext<SignUpFormData>();
  const { touchedFields } = useContext(FormInputCtx);
  const isTouched = touchedFields[allProps.variety];

  const sxStyles: SxProps = {
    ...(rounded && {
      '& .MuiFormLabel-root': {
        transform: 'translate(20px, 16px) scale(1)',
      },
      '& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root.MuiFormLabel-filled': {
        transform: 'translate(20px, 7px) scale(0.75)',
      },
      '& .MuiInputBase-root': {
        borderRadius: '30px',
        padding: '0 20px',
      },
      '& .MuiInputBase-root input': {
        paddingTop: '5px',
        paddingBottom: '5px',
        borderRadius: 0,
      },
      '& .MuiInputBase-root::before,.MuiInputBase-root::after': {
        width: 'calc(100% - 40px)',
        margin: '0 auto',
      },
    }),
    ...(allProps.labelMode === 'placeholder' && {
      '& input': {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
      '& input::placeholder': {
        fontSize: '16px',
      },
    }),
  };

  const { width } = useScreenResize();

  return (
    <FormControl
      sx={ sxStyles }
      variant={ variant }
      error={ customErrCond || (!!errors[allProps.variety]?.message && isTouched) }
      fullWidth={ fullWidth }>
      <RenderIf condition={ allProps.labelMode === 'label' }>
        <InputLabel htmlFor={ allProps.inputId }>{ allProps.label }</InputLabel>
      </RenderIf>
      <Controller
        defaultValue=""
        name={ allProps.variety }
        control={ control }
        render={ allProps.render }
      />
      <RenderIf condition={ allProps.errorDisplay === 'text' || width <= ERR_MSG_BREAKPOINT }>
        <InputErrorMessage id={ `err-${ allProps.variety }` }>
          { CustomErrorMsg || (isTouched && errors[allProps.variety]?.message) || EMPTY_ERR_MSG }
        </InputErrorMessage>
      </RenderIf>
      <RenderIf condition={ allProps.errorDisplay === 'tooltip' && width > ERR_MSG_BREAKPOINT }>
        <TdTooltip
          id={ `err-${ allProps.variety }` }
          showCond={ customErrCond || (isTouched && !!errors[allProps.variety]?.message) }
        >
          { CustomErrorMsg || errors[allProps.variety]?.message }
        </TdTooltip>
      </RenderIf>
    </FormControl>
  );
};

export const sharedInputProps = (props: TdInputProps): InputBaseProps => ({
  placeholder: props.labelMode === 'placeholder' ? props.label : undefined,
  'aria-label': props.labelMode === 'placeholder' ? props.label : undefined,
});
