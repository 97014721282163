import { GlobalBlok } from '@/components/sb-bloks/universal/GlobalBlok';
import { GlobalReference } from '@/components/sb-bloks/universal/GlobalReference';

import DefaultPageBlok from './DefaultPageBlok';

export const universalBloks = {
  default_page: DefaultPageBlok,
  global: GlobalBlok,
  global_reference: GlobalReference,
};
