import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { useAppSelector } from '@/hooks';

import { isWhiteListedDomain, trackNeverBounceValidation } from '../../features/neverbounce';

export const useNeverBounce = (eventCategory: string) => {
  const { query } = useRouter();
  const { signupTrigger } = useAppSelector(state => state.tracking);

  const emailValidation = useCallback((email: string): Promise<NeverBounceValidationStatus> => new Promise(resolve => {
    const queryParams = query as Record<string, string>;
    const homeCta = queryParams?.['homecta'] ?? signupTrigger;

    if (window._nb && email && !isWhiteListedDomain(email)) {
      window._nb.api.getValidatePublic(
        email,
        (validationResult) => {
          if (!validationResult.is(['valid', 'catchall', 'unknown'])) {
            trackNeverBounceValidation(validationResult.response?.result, 'valid', email, homeCta, true, eventCategory);
            resolve('invalid');
          } else {
            trackNeverBounceValidation(validationResult.response?.result, 'valid', email, homeCta, false, eventCategory);
            resolve('valid');
          }
        },
        (err) => {
          if (err) {
            trackNeverBounceValidation('errorUnknown', 'invalid', email, homeCta, false, eventCategory);
            resolve('valid');
          }
        },
      );
    } else {
      resolve('valid');
    }

  }), [signupTrigger, eventCategory, query]);

  return { emailValidation };
};
