import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { LinkButtonProps } from '@/components/uikit/link-button/LinkButton';
import LinkButton from '@/components/uikit/link-button/LinkButton';
import type { ButtonStoryblok } from '@/sb-types';

type ButtonBlokProps = {
  blok: SbBlokData<ButtonStoryblok>;
}

type UserStyles = Pick<ButtonStoryblok, 'marginObj'>

const propsToForward: (keyof LinkButtonProps)[] = [
  'noBorderCorner',
  'noHover',
  'variant',
  'textUnderline',
  'endIcon',
  'color',
];

const StyledLinkButton = styled(LinkButton, {
  shouldForwardProp: (prop) => isPropValid(prop) || propsToForward.includes(prop as keyof LinkButtonProps),
})<UserStyles>(({ marginObj, theme, color, variant }) => ({
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  '&.MuiButton-root:active': {
    backgroundColor: theme.palette[color || 'primaryCustom']['dark'],
    border: `2px solid ${ theme.palette[color || 'primaryCustom']['dark'] }`,
  },
  ...(variant === 'outlined' && {
    '&.MuiButton-root:active': {
      border: `2px solid ${ theme.palette[color || 'primaryCustom']['dark'] }`,
      backgroundColor: theme.palette[color || 'primaryCustom']['dark'],
      color: '#ffffff',
    },
  }),
  ...(variant === 'text' && {
    '&.MuiButton-root:active': {
      backgroundColor: theme.palette[color || 'primaryCustom']['dark'],
      color: '#ffffff',
    },
  }),
  ...marginObj,
  ...theme.utils.objStyleBreakpoints(marginObj),
}));

export const ButtonBlok: FC<ButtonBlokProps> = ({ blok }) => (
  <StyledLinkButton
    href={ blok.link?.url || blok.link?.cached_url || '#' }
    color={ blok.color || 'secondary' }
    noHover={ !blok.hover } textUnderline={ blok.hasUnderline }
    noBorderCorner={ blok.noBorderCorner }
    endIcon={ blok.withArrow ? <ArrowForwardIcon /> : undefined }
    { ...storyblokEditable(blok) }
    { ...blok }
  >
    { blok.label }
  </StyledLinkButton>
);
