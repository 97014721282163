import cookiesUtil from '@/utils/cookies.util';
import trackingUtils, { EVENT_NAMES } from '@/utils/tracking.util';


export const neverbounceValidationStatus: Record<NeverBounceStatusCode, string> = {
  valid: 'Valid',
  invalid: 'Invalid',
  disposable: 'Disposable',
  catchall: 'Unverifiable',
  unknown: 'Unverifiable',
  errorUnknown: 'Unknown', // for tracking requirements
};

export function setNBFailCookie() {
  const attempts = +cookiesUtil.getCookie('_nba');
  cookiesUtil.setCookie('_nba', JSON.stringify(attempts + 1));
}

export function nbEmailValidationTrack(status: NeverBounceStatusCode, eventType: 'valid' | 'invalid', email: string, homecta: string) {
  trackingUtils.trackEvent({
    event: (eventType === 'valid') ? EVENT_NAMES?.NEVERBOUNCE?.VALIDATION : EVENT_NAMES?.NEVERBOUNCE?.FAILED,
    properties: {
      pageorigin: window.document.URL,
      numberofattempts: cookiesUtil.getCookie('_nba'),
      emailvalidation: neverbounceValidationStatus[status].toLowerCase(),
      email,
      homecta,
    },
  });
}

export function trackNeverBounceValidation(status: NeverBounceStatusCode, eventType: 'valid' | 'invalid', email: string, homeCta: string, sendTrack: boolean, category: string) {
  if (sendTrack) {
    fireUnsuccessfulTrack(category, email);
  }
  setNBFailCookie();
  nbEmailValidationTrack(status, eventType, email, homeCta);
}

export function fireUnsuccessfulTrack(category: string, email: string) {
  trackingUtils.trackEvent({
    event: EVENT_NAMES.GENERAL.SIGNUP_UNSUCCESSFUL,
    category,
    properties: {
      erroremail: {
        errormessage: 'Please enter a valid email address!',
        field: 'email',
        value: email,
      },
    },
  });
}
