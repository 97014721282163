import type { FC } from 'react';
import type { Settings } from 'react-slick';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { SlickSlider } from '@/components/uikit/SlickSlider';
import type { SliderStoryblok } from '@/sb-types';

import { theme } from '../../../theme';


type SliderBlokProps = {
  blok: SbBlokData<SliderStoryblok>;
}

type UserStyles = Pick<SliderStoryblok, 'sliderTrackPadding' | 'slidesSpacing' | 'dotsColor'>

const StyledSlickSlider = styled(SlickSlider)<UserStyles>(
  ({ theme, sliderTrackPadding, slidesSpacing, dotsColor }) => ({
    ...dotsColor,
    '& .slick-track': {
      ...sliderTrackPadding,
      ...theme.utils.objStyleBreakpoints(sliderTrackPadding),
    },
    '& .slick-slide': {
      ...slidesSpacing,
      ...theme.utils.objStyleBreakpoints(slidesSpacing),
    },
  }),
);

export const SliderBlok: FC<SliderBlokProps> = ({ blok }) => {
  const sliderSettings: Settings = {
    ...blok,
    autoplaySpeed: blok?.autoplaySpeed ? +blok.autoplaySpeed : undefined,
    slidesToShow: blok?.slidesToShow ? +blok.slidesToShow : undefined,
    slidesToScroll: blok?.slidesToScroll ? +blok.slidesToScroll : undefined,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg - 1,
        settings: {
          slidesToShow: blok?.lgSlidesToShow ? +blok.lgSlidesToShow : undefined,
          slidesToScroll: blok?.lgSlidesToScroll ? +blok.lgSlidesToScroll : undefined,
          dots: blok?.lgDots,
          autoplay: blok?.lgAutoPlay,
          infinite: blok?.lgInfinite,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md - 1,
        settings: {
          slidesToShow: blok?.mdSlidesToShow ? +blok.mdSlidesToShow : undefined,
          slidesToScroll: blok?.mdSlidesToScroll ? +blok.mdSlidesToScroll : undefined,
          dots: blok?.mdDots,
          autoplay: blok?.mdAutoPlay,
          infinite: blok?.mdInfinite,
          centerPadding: blok?.mdCenterPadding,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm - 1,
        settings: {
          slidesToShow: blok?.smSlidesToShow ? +blok.smSlidesToShow : undefined,
          slidesToScroll: blok?.smSlidesToScroll ? +blok.smSlidesToScroll : undefined,
          dots: blok?.smDots,
          autoplay: blok?.smAutoPlay,
          infinite: blok?.smInfinite,
          centerPadding: blok?.smCenterPadding,
          centerMode: blok?.smCenterMode,
        },
      },
    ],
  };

  return (
    <StyledSlickSlider
      sliderTrackPadding={ blok.sliderTrackPadding }
      slidesSpacing={ blok.slidesSpacing }
      { ...sliderSettings }
      { ...storyblokEditable(blok) }
    >
      { blok.slides.map(slide => (
        <StoryblokComponent blok={ slide } key={ slide._uid } />
      )) }
    </StyledSlickSlider>
  );
};
