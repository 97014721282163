import type { AllowedColors } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { TabProps } from '@mui/material/Tab';
import Tab from '@mui/material/Tab';
import type { TabsProps } from '@mui/material/Tabs';
import Tabs from '@mui/material/Tabs';

import { breakpoints } from '../../../theme/breakpoints.theme';


export type BasicTabProps = Omit<TabProps, 'color'> & {
  color?: keyof AllowedColors;
};

export const a11yProps = (index: number, idPrefix: string) => ({
  id: `${ idPrefix }-tab-${ index }`,
  'aria-controls': `${ idPrefix }-tabpanel-${ index }`,
});

const tabletBp = `@media (max-width: ${ breakpoints!.values!.lg - 1 }px)`;
const mobileBp = `@media (max-width: ${ breakpoints!.values!.sm - 1 }px)`;

export const BasicTabs = styled(Tabs)<TabsProps>(() => ({
  minHeight: '36px',

  '& .MuiTabs-flexContainer': {
    columnGap: '15px',
    rowGap: '15px',
    justifyContent: 'center',
    flexFlow: 'row wrap',
    [tabletBp]: {
      flexFlow: 'row',
      justifyContent: 'flex-start',
    },
  },
  '& .MuiTabs-indicator': { display: 'none' },
}));

export const BasicTab = styled(Tab)<BasicTabProps>(() => ({
  backgroundColor: '#fff',
  textTransform: 'capitalize',
  borderRadius: '45px',
  border: '2px solid #0066FF',
  color: '#0066FF',
  padding: '15px',
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '24px',
  minHeight: '36px',
  [tabletBp]: {
    borderRadius: '30px',
  },
  [mobileBp]: {
    fontSize: '15px',
  },
  '&.Mui-selected': {
    backgroundColor: '#0066FF',
    borderColor: '#0066FF',
    color: '#FFFFFF',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '600',
    [mobileBp]: {
      borderRadius: '30px',
      fontSize: '15px',
    },
  },
  '&:hover': {
    opacity: '0.8',
  },
}));

