import type { FC } from 'react';
import { css } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent } from '@storyblok/react';

import TdLink, { LinkTrackingCtx } from '@/components/uikit/link/TdLink';
import type { FooterNavItemStoryblok } from '@/sb-types';

import { web3Theme } from '../../../theme';

type FooterNavItemBlokProps = {
  blok: SbBlokData<FooterNavItemStoryblok>
}

const styles = {
  NavItem: css`
    text-align: left;
    margin-top: var(--space-16);
    vertical-align: top;
    display: block;
    list-style: none;
  `,
}

export const FooterNavItemBlok: FC<FooterNavItemBlokProps> = ({ blok }) => {
  const linkUrl = blok.href?.cached_url.startsWith('http')
    ? blok.href?.cached_url // External link, use as-is
    : `/${ blok.href?.cached_url.replace(/^\/+/, '') }`; // Internal link, ensure it starts with a single /

  return (<ThemeProvider theme={ web3Theme }>
    <LinkTrackingCtx.Provider value={{ location: `${ blok.location }`, category: `${ blok.pageCategory }` }}>
      <li css={ styles.NavItem } key={ blok.title }>
        <TdLink href={ linkUrl || '' } target={ blok.target }>
          {
            blok?.itemTitle?.map(footerNavItem => (
              <StoryblokComponent blok={ footerNavItem } key={ footerNavItem._uid } />
            ))
          }
        </TdLink>
      </li>
    </LinkTrackingCtx.Provider>
  </ThemeProvider>
  )
}
