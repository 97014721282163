import type { FC } from 'react';
import { useEffect, useState } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ThemeProvider } from '@mui/material';
import Typography from '@mui/material/Typography';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';

import RenderIf from '@/components/shared/render-if/RenderIf';
import BasicCard from '@/components/uikit/basic-card/BasicCard';
import FlexBox from '@/components/uikit/flex-box/FlexBox';
import TdLink from '@/components/uikit/link/TdLink';
import type { NavTabStoryblok } from '@/sb-types';

import { NavProvider, useNavContext } from '../../../context/Navigation/useNavContext';
import { web3Theme } from '../../../theme';

import styles from '../../../../src/components/shared/nav-tab/NavTab.module.scss';

type NavTabProps = {
  blok: SbBlokData<NavTabStoryblok>
}

export const NavTabBlok: FC<NavTabProps> = (
  {
    blok,
    ...allAttributes
  },
) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedTab, setExpandedTab] = useState<string | null>(null);
  const { selectedNav, setSelectedNav } = useNavContext();

  useEffect(() => {
    const handleDocumentClick = (ev: MouseEvent) => {
      if (!ev.composedPath().some((target: EventTarget) => target instanceof HTMLElement && target.getAttribute('role') === 'banner')) {
        setIsExpanded(false);
      }
    };
    if (selectedNav != expandedTab) {
      setIsExpanded(false);
    }

    window.document.addEventListener('click', handleDocumentClick);

    return () => window.document.removeEventListener('click', handleDocumentClick);
  }, [blok.title, selectedNav]);

  const handleNavTabClick = (tabTitle: string) => {
    if (expandedTab != tabTitle) {
      setSelectedNav(tabTitle);
      setExpandedTab(tabTitle);
      setIsExpanded(true);
    }
    setSelectedNav(tabTitle);
    setIsExpanded(!isExpanded);
  };

  return (
    <ThemeProvider theme={ web3Theme }>
      <NavProvider>
        <li
          { ...blok } { ...storyblokEditable(blok) }
          className={ classNames(styles.NavTab, {
            [styles.expanded]: expandedTab === blok.title,
            [styles.active]: isExpanded,
          }) }
          role="none"
          { ...allAttributes }
        >
          <RenderIf condition={ `${ blok.mode }` === 'dropdown' }>
            <FlexBox
              columnGap={ 1 }
              alignItems="center"
              className={ styles.NavTabBox }
              attributes={{
                role: 'menuitem',
                'aria-haspopup': true,
                'aria-label': blok.title,
                'aria-expanded': isExpanded,
              }}
            >
              <FlexBox
                className={ styles.NavTabBox__Box }
                alignItems="center"
                attributes={{
                  onClick: () => handleNavTabClick(blok.title ?? ''),
                }}
              >
                <Typography
                  variant="body2"
                  className={ classNames(styles.NavTab__Title, {
                    [styles.active]: isExpanded,
                  }) }
                >
                  { blok.title }
                </Typography>
                <ExpandMore
                  className={ classNames(styles.NavTab__ExpandIcon, {
                    [styles.active]: isExpanded,
                  }) } sx={{ fontSize: 20 }} />
              </FlexBox>
              <div
                className={ classNames(styles.NavTabBox__Dropdown, {
                  [styles.expanded]: expandedTab === blok.title,
                }) }>
                <BasicCard
                  className={ styles.NavTabBox__Dropdown__Box }
                  contentClass={ styles.NavTabBox__Dropdown__CardContent }
                  borderRadius="16px" shadowStyle="default"
                  data-expanded={ isExpanded }
                  noBorderCorner="topRight">
                  <FlexBox
                    as="nav"
                    justifyContent="space-between"
                    className={ styles.NavTabBox__Dropdown__Box__Content }
                  >
                    { isExpanded && blok?.navItemOrColumn?.map(nestedBlok => (
                      <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />
                    )) }
                  </FlexBox>
                </BasicCard>
              </div>
            </FlexBox>
          </RenderIf>
          <RenderIf condition={ blok.mode === 'link' }>
            <TdLink
              href={ blok.href?.cached_url }
              role="menuitem" aria-label={ blok.title }
              className={ styles.NavTabBox__Link }
            >
              <Typography variant="body2" className={ styles.NavTab__Title }>
                { blok.title }
              </Typography>
            </TdLink>
          </RenderIf>
        </li>
      </NavProvider>
    </ThemeProvider>
  );
}
