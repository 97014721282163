import type { AllowedColors } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { SimplePaletteColorOptions } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

export type AdditionalButtonProps = {
  /** Defines the corner that won't the border radius. By default, this is `bottomLeft` */
  noBorderCorner?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'none';

  /** Determines whether to use an underline for outline variant. This is `true` by default */
  textUnderline?: boolean;

  /** Indicates that button won't have hover state. By default, this is `false` */
  noHover?: boolean;

  /** Determines if the button will have rounder corners. By default, this is false */
  rounded?: boolean;

  /** Determines if the button will have all text in caps. By default, this is false */
  uppercase?: boolean;

  /** This will be used to complement the color property.
   * @example
   * <TdButton color="neutral" colorType="light"/>
   */
  colorType?: keyof SimplePaletteColorOptions;

  color?: keyof AllowedColors;

  /** Determines if the button will have a thicker border. By default, this is false */
  cta?: boolean;
}

export type TdButtonProps = ButtonProps & AdditionalButtonProps;

export const skipPropForwardingFn = <CustomProps extends Record<string, unknown>>(
  propsToSkip: Array<keyof CustomProps>,
  prop: PropertyKey,
): boolean => !propsToSkip.includes(prop as string);

const TdButtonCustom = styled(Button, {
  shouldForwardProp: (prop) =>
    skipPropForwardingFn<AdditionalButtonProps>(
      ['rounded', 'noBorderCorner', 'noHover', 'textUnderline', 'uppercase', 'colorType', 'cta'],
      prop,
    ),
})<TdButtonProps>((
  {
    variant = 'text',
    theme,
    color,
    colorType = 'main',
    size = 'large',
    textUnderline = true,
    noHover = false,
    rounded = false,
    uppercase = false,
  },
) => ({
  textTransform: uppercase ? 'uppercase' : 'none',
  borderRadius: 12,
  '& .MuiButton-iconSizeLarge > *': {
    fontSize: '16px',
  },
  ...(size === 'large' && {
    padding: '15px',
    fontSize: '20px',
    fontWeight: 600,
  }),
  ...(variant === 'text' && textUnderline && {
    color: theme.palette[color || 'primaryCustom'][colorType],
    '&.MuiButton-root::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '8px',
      margin: '0 auto',
      width: '100%',
      height: '1px',
      backgroundColor: theme.palette[color || 'primaryCustom'][colorType],
    },
  }),
  ...(variant === 'outlined' && {
    border: `2px solid ${ theme.palette[color || 'primaryCustom'][colorType] }`,
    color: theme.palette[color || 'primaryCustom'][colorType],
    '&.MuiButton-root:hover': {
      backgroundColor: 'transparent',
      border: `2px solid ${ theme.palette[color || 'primaryCustom']['light'] }`,
      color: theme.palette[color || 'primaryCustom']['light'],
    },
  }),
  ...(variant === 'contained' && {
    border: `2px solid ${ theme.palette[color || 'primaryCustom'][colorType] }`,
    '&.MuiButton-root:hover': {
      backgroundColor: theme.palette[color || 'primaryCustom']['light'],
      border: `2px solid ${ theme.palette[color || 'primaryCustom']['light'] }`,
    },
  }),
  ...(variant === 'text' && {
    '&.MuiButton-root:hover': {
      transition: 'all 0.3s ease',
      color: theme.palette[color || 'primaryCustom']['light'],
      backgroundColor: 'transparent',
    },
  }),
  ...(noHover && variant === 'text' && {
    '&.MuiButton-root:hover': {
      transition: 'all 0.3s ease',
      color: theme.palette[color || 'primaryCustom']['light'],
      backgroundColor: 'transparent',
    },
  }),
  ...(rounded && { borderRadius: '26px' }),
}));

export default TdButtonCustom;
