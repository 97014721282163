import { integrationsBloks } from '@/components/sb-bloks/integrations';
import { predefinedSectionsBloks } from '@/components/sb-bloks/predefined-sections';
import { uiKitBloks } from '@/components/sb-bloks/ui-kit';
import { universalBloks } from '@/components/sb-bloks/universal';

export const storyblokComponents = {
  ...integrationsBloks,
  ...predefinedSectionsBloks,
  ...uiKitBloks,
  ...universalBloks,
}
