import type { FC } from 'react';
import { ThemeProvider } from '@mui/material';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent } from '@storyblok/react';

import type { GlobalStoryblok } from '@/sb-types';

import { web3Theme } from '../../../theme';


type GlobalBlokProps = {
  blok: SbBlokData<GlobalStoryblok>;
}

export const GlobalBlok: FC<GlobalBlokProps> = ({ blok }) => (
  <ThemeProvider theme={ web3Theme }>
    { blok.contentBlocks?.map(gridBlok => (
      <StoryblokComponent blok={ gridBlok } key={ gridBlok._uid } />
    )) }
  </ThemeProvider>
);
