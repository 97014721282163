import type React from 'react';

import type { TdButtonProps } from '@/components/uikit/button/TdButton';
import TdButtonCustom from '@/components/uikit/button/TdButtonCustom';
import TdLink from '@/components/uikit/link/TdLink';

export type LinkButtonProps = {
  href?: string;
  linkName?: string;
  linkType?: 'text' | 'button' | 'img';
  anchorClassName?: string;
  anchorAttr?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
  inNewTab?: boolean;
} & TdButtonProps

const LinkButtonCustom: React.FC<LinkButtonProps> = (
  {
    children,
    href,
    linkType = 'button',
    linkName,
    anchorClassName,
    anchorAttr,
    inNewTab,
    ...other
  },
) => (
  <TdLink
    className={ anchorClassName } { ...anchorAttr } inNewTab={ inNewTab } href={ href }
    linkType={ linkType } linkName={ linkName }>
    <TdButtonCustom data-testid={ `TdButton-${ linkName }` } { ...other } tabIndex={ -1 }>
      { children }
    </TdButtonCustom>
  </TdLink>
);

export default LinkButtonCustom;
