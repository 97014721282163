import type React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import type { TdInputProps } from '@/components/uikit/Input';
import ControlledInput from '@/components/uikit/Input/ControlledInput';
import { PasswordRules } from '@/components/uikit/Input/PrivateComponents/PasswordRules';
import { sharedInputProps } from '@/components/uikit/Input/PrivateComponents/TdFormControl';
import { TdInputBox } from '@/components/uikit/Input/PrivateComponents/TdInputBox';
import { FormInputCtx } from '@/components/uikit/Input/TdInput';
import type { SignUpFormData } from '@/types/signup.types';

export const TdBoxInputPassword: React.FC<TdInputProps> = (props) => {
  const { watch, getFieldState, formState: { errors }} = useFormContext<SignUpFormData>();
  const { touchedFields } = useContext(FormInputCtx);
  const watchValue = watch('password') || '';
  const { invalid } = getFieldState('password');

  const [hasPasswordReveal, setHasPasswordReveal] = useState(false);
  const [iconColor, setIconColor] = useState<'error' | 'primary' | undefined>(undefined);

  useEffect(() => {
    const errorColor = errors.password?.message && 'error';
    const validColor = !!watchValue && !invalid && 'primary';
    setIconColor(errorColor || validColor || undefined);
  }, [errors.password?.message, invalid, watchValue, touchedFields]);

  const handleClickShowPassword = () => setHasPasswordReveal(prevState => !prevState);

  return (
    <TdInputBox
      variety="password"
      rounded={ props.rounded }
      labelMode={ props.labelMode }
      errorDisplay={ props.errorDisplay }
      render={ ({ field }) => (
        <>
          <ControlledInput
            { ...field }
            { ...sharedInputProps(props) }
            disabledOnBlurValidation={ true }
            placeholder="Password"
            variety="password"
            variant={ props.variant }
            id={ props.inputId } type={ hasPasswordReveal ? 'text' : 'password' }
            autoComplete="current-password"
            aria-errormessage="err-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={ handleClickShowPassword }
                  edge="end"
                >
                  { hasPasswordReveal ? <Visibility /> : <VisibilityOff /> }
                </IconButton>
              </InputAdornment>
            }
          />
          <PasswordRules
            watchValue={ watchValue }
            iconColor={ iconColor }
            touchedFields={ touchedFields }
          />
        </>
      )
      }
      { ...props }
    />
  );
};
