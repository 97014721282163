import type { FC } from 'react';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { EmbeddedVideoStoryblok } from '@/sb-types';


type EmbeddedVideoBlokProps = {
  blok: SbBlokData<EmbeddedVideoStoryblok>;
}

const VideoWrapper = styled.div<EmbeddedVideoStoryblok>(({ theme, marginObj, paddingObj, sizeObj }) => ({
  width: '100%',

  '& iframe': {
    width: '100%',
    aspectRatio: '16 / 9',
    border: 0,
  },

  ...marginObj,
  ...paddingObj,
  ...sizeObj,
  ...theme.utils.objStyleBreakpoints(marginObj, paddingObj, sizeObj),
}));

export const EmbeddedVideoBlok: FC<EmbeddedVideoBlokProps> = ({ blok }) => {
  if (!blok.videoUrl?.url) return null;

  return (
    <VideoWrapper { ...blok } { ...storyblokEditable(blok) }>
      <iframe
        src={ blok.videoUrl.url }
        title={ blok.title }
        allow={ `${ blok.playerOptions?.join(';') }` }
        allowFullScreen={ blok.allowFullScreen } />
    </VideoWrapper>
  );
};
