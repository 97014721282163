import type { FC } from 'react';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import TdLink from '@/components/uikit/link/TdLink';
import type { LinkStoryblok } from '@/sb-types';


type LinkBlokProps = {
  blok: SbBlokData<LinkStoryblok>;
}

export const LinkBlok: FC<LinkBlokProps> = ({ blok: { href, ...allBlok }}) => {
  const url = href?.linktype === 'story' ? href?.cached_url : href?.url;

  return (
    <Link href={ url } { ...allBlok } { ...storyblokEditable(allBlok) }>
      {
        allBlok.content.map((nestedBlok) => (<StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
      }
    </Link>
  );
};

const Link = styled(TdLink)<SbBlokData<LinkStoryblok>>(({ theme, config, marginObj, paddingObj }) => ({
  display: 'inline-block',

  ...marginObj,
  ...paddingObj,
  ...config,

  ...theme.utils.objStyleBreakpoints(marginObj, paddingObj, config),
}));
