import type React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import type { Theme } from '@mui/material/styles';

type ErrorMsgProps = {
  id: string;
  children?: React.ReactNode;
};

const defaultHelperTextStyle = (theme: Theme) => ({
  fontSize: theme.typography.caption.fontSize,
  marginBottom: theme.spacing(1.25),
});

export const InputErrorMessage: React.FC<ErrorMsgProps> = ({ children, id }) => (
  <FormHelperText error css={{ ...defaultHelperTextStyle }} id={ id }>
    { children }
  </FormHelperText>
);
