import type { FC } from 'react';
import { useContext } from 'react';

import { FormInputCtx } from '@/components/uikit/Input/TdInput';
import { getMainDomain, getTdcDomain } from '@/utils/cookies.util';

export const UserRegisteredMsg: FC<{ email: string }> = ({ email }) => {
  const { asyncEmailErr } = useContext(FormInputCtx);

  const domainUrl = asyncEmailErr === 'loginExistsTdc' ? getTdcDomain() : `2.${ getMainDomain() }`;
  const signInUrl = `https://${ domainUrl }/login?email=${ email }`;

  return (
    <>
      It looks like this account already exists in our system. Would you like to&nbsp;
      <a
        href={ signInUrl }
        data-testid="error-sign-in-link"
        className="sign-in-link link"
      >
        Sign In
      </a>
      &nbsp;instead?
    </>
  );
};
