import type { FC } from 'react';

import type { TdInputProps } from '@/components/uikit/Input';
import { ValidityAdornment } from '@/components/uikit/Input';
import ControlledInput from '@/components/uikit/Input/ControlledInput';
import { sharedInputProps } from '@/components/uikit/Input/PrivateComponents/TdFormControl';
import { getComponentToRender, isBoxedContainer } from '@/utils/input-component.util';

export const TdInputName: FC<TdInputProps> = (props) => {
  const boxedContainer = isBoxedContainer(props.containerType);
  const ComponentToRender = getComponentToRender(props.containerType);
  const placeholderText = props.placeholder ?? props.label;

  return (
    <ComponentToRender
      variety="name"
      rounded={ props.rounded }
      labelMode={ props.labelMode }
      errorDisplay={ props.errorDisplay }
      render={ ({ field }) => (
        <ControlledInput
          { ...field }
          { ...sharedInputProps(props) }
          placeholder={ placeholderText }
          variety="name"
          variant={ props.variant }
          id={ props.inputId }
          type="text"
          autoComplete={ props.autoComplete }
          aria-errormessage="err-name"
          endAdornment={ !boxedContainer && <ValidityAdornment variety="name" /> }
        />
      ) }
      { ...props }
    />
  );
};
