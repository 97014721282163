import type { ComponentType, FC, ReactNode } from 'react';

type RenderIfProps = {
  condition: boolean | undefined;
  children?: ReactNode;
}

type RenderIfHoCProps = {
  renderIf?: boolean;
}

const RenderIf: FC<RenderIfProps> = ({ condition, children }) => (
  <>
    { condition ? children : null }
  </>
);

export default RenderIf;

export const withRenderIf = <P extends Record<string, unknown>>(Component: ComponentType<P>) => (
  function RenderIfWrapper(props: P & RenderIfHoCProps) {
    if (props.renderIf == undefined) return <Component { ...props } />;

    return props.renderIf ? <Component { ...props } /> : null;
  }
);
