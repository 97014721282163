import type { FC } from 'react';
import type { SbBlokData } from '@storyblok/js';
import Image from 'next/image';

import TdImage from '@/components/uikit/image-box/TdImage';
import type { FeedbackSectionStoryblok } from '@/sb-types';

import 'mdb-react-ui-kit/dist/css/mdb.min.css';

type FeedbackSectionProps = {
  blok: SbBlokData<FeedbackSectionStoryblok>;
}

//create class for rating image style


export const FeedbackBlok: FC<FeedbackSectionProps> = ({ blok }) => (
  <section data-testid="feedback-section" className="feedback">
    <div className="row ">
      <div className="col-lg-4 col-md-12 left-col">
        <h2 className="feedback-title">{ blok.heading ? blok.heading : 'Don’t take our word for it' }</h2>
        <p>{ blok.paragraph ? blok.paragraph : 'Teams from across the globe share how Time Doctor helps them become better by the second.' }</p>
        <div className="row">
          <div className="col-md-4 col-4 col-xs-6">
            <div className="rating">
              <div className="stars">
                <Image src="/images/home-page/stars.png" className="ratingImgResponsive" alt="Star Rating" width="152" height="33" />
              </div>
              <div>
                <Image src="/images/home-page/capterra-logo.png" className="ratingImgResponsive" alt="Capterra logo" width="91" height="21" />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-4 col-xs-6">
            <div className="rating">
              <div className="stars">
                <Image src="/images/home-page/stars.png" className="ratingImgResponsive" alt="Star Rating" width="152" height="33" />
              </div>
              <div>
                <Image src="/images/home-page/getapp-logo.png" className="ratingImgResponsive" alt="GetApp logo" width="101" height="19" />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 col-xs-6">
            <div className="rating">
              <div className="stars">
                <Image src="/images/home-page/stars.png" className="ratingImgResponsive" alt="Star Rating" width="152" height="33" />
              </div>
              <div>
                <Image src="/images/home-page/g-logo.png" className="ratingImgResponsive" alt="G2 logo" width="32" height="33" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 col-md-12 right-col">
        <div className="row">
          <div className="col-md-5 col-sm-5">
            <div className="img_div">
              <img
                src="/_static/svg/green-dots.svg"
                alt=""
                className="dots" />
              <TdImage
                className="client-img"
                disableShadow={ true }
                src="/images/home-page/client_img.png" alt=""
                width={ 244 } height={ 349 }
                loading="lazy" />
            </div>
          </div>
          <div className="col-md-7 col-sm-12">
            <div className="feedback-col">
              <p>“{ blok.review ? blok.review : 'Because we use Time Doctor, we’re able to have a flexible schedule. It allows you to adapt to the work schedule that’s best for you.' }”</p>
              <h3 className="name">{ blok.author ? blok.author : 'Jessamine Eaton' }</h3>
              <h4 className="designation">{ blok.author_role ? blok.author_role : 'Manager, Habitium' }</h4>
              <Image
                width="136" height="29"
                src="/images/home-page/feedback-logo.png"
                alt="Habitium logo"
                className="img-responsive" />
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
);
