import type { PasswordRule } from '@/types/signup.types';

abstract class PasswordRuleStrategy implements PasswordRule {
  constructor(public label: string, private validationFunction: (value: string) => boolean) {}

  validate(value: string): boolean {
    return this.validationFunction(value);
  }
}

export class MinLengthRule extends PasswordRuleStrategy {
  constructor() {
    super('At least 8 characters', value => value.length >= 8);
  }
}

export class UppercaseLowercaseRule extends PasswordRuleStrategy {
  constructor() {
    super('Contains an uppercase and lowercase letter', value =>
      /(?=.*[a-z])(?=.*[A-Z])/.test(value));
  }
}

export class NumberRule extends PasswordRuleStrategy {
  constructor() {
    super('Contains a number', value => /\d/.test(value));
  }
}

export class SpecialCharRule extends PasswordRuleStrategy {
  constructor() {
    super('Contains a special character', value =>
      /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/.test(value));
  }
}

export class PasswordRuleManager {
  public static rules() {
    return [
      new MinLengthRule(),
      new UppercaseLowercaseRule(),
      new NumberRule(),
      new SpecialCharRule(),
    ]
  }
}

