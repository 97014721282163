import type { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { styles } from '@/components/sb-bloks/predefined-sections/WhitePaperBlok/WhitePaperBlok.styles';
import BasicCard from '@/components/uikit/basic-card/BasicCard';
import { FlexBoxV2 } from '@/components/uikit/flex-box/FlexBox';
import TdImage from '@/components/uikit/image-box/TdImage';
import type { ButtonStoryblok, WhitePaperSectionStoryblok } from '@/sb-types';

import { renderRichText } from '../../../../storyblok/render-rich-text';

export type WhitePaperBlokProps = {
  blok: Omit<SbBlokData<WhitePaperSectionStoryblok>, 'component' | '_uid'>;
}

const ImgsColumn = styled(MuiBox)(({ theme }) => css`
  position: relative;
  width: 100%;

  & img {
    height: auto;
  }

  ${ theme.breakpoints.down('lg') } {
    & img {
      width: 250px;
    }
  }

  ${ theme.breakpoints.down('md') } {
    height: 332px;
  }
`);

export const WhitePaperBlok: FC<WhitePaperBlokProps> = ({ blok }) => (
  <FlexBoxV2 css={ styles.Root } { ...storyblokEditable(blok) }>
    <BasicCard
      borderRadius="25px"
      noBorderCorner="topLeft"
      css={ styles.Card }
      contentClass="Card-content">
      <FlexBoxV2 mdWrap height="100%">
        <MuiBox width={{ zero: '100%', md: '50%' }} pb={{ zero: 4, lg: 8 }} flex="1 0 auto">
          <Typography variant="subtitle2" color="#F7B82F" mb={ 1 }>
            { blok.paperLabel }
          </Typography>
          <Typography variant="h2" color="#fff" mb={ 1.5 }>
            { blok.title }
          </Typography>

          { blok.content && renderRichText(blok.content) }
          { blok.description && <Typography
            variant="body2" color="#fff" mb={ 5 }
            dangerouslySetInnerHTML={{ __html: blok.description }} />
          }
          {
            blok.cta.map((ctaBlok: ButtonStoryblok) => <StoryblokComponent blok={ ctaBlok } key={ ctaBlok._uid } />)
          }
        </MuiBox>
        <ImgsColumn>
          <figure css={ styles.FadedImgWrapper }>
            <TdImage
              borderRadius={ 0 }
              width={ blok.image?.meta_data?.width } height={ blok.image?.meta_data?.height }
              src={ `${ blok.image.filename }/m/filters:quality(${ 80 })` } alt={ blok.image?.alt || '' }
              quality={ 80 }
            />
          </figure>
          <TdImage
            borderRadius={ 0 }
            width={ blok.image?.meta_data?.width } height={ blok.image?.meta_data?.height }
            src={ `${ blok.image.filename }/m/filters:quality(${ 80 })` } alt={ blok.image?.alt || '' }
            quality={ 90 }
            css={ styles.NormalImg } />
        </ImgsColumn>
      </FlexBoxV2>
    </BasicCard>
  </FlexBoxV2>
);
