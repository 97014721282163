import { css } from '@emotion/react';
import type { Theme } from '@mui/material/styles';

export const heroSectionStyles = {
  FlexRow: css`
    column-gap: var(--hero-section-flexrow-col-gap);
    row-gap: var(--hero-section-flexrow-row-gap);
  `,
  Image: css`
    border-radius: 20px;
  `,
  VideoContainer: (theme: Theme) => css`
    width: var(--hero-section-video-width, auto);
    height: var(--hero-section-video-height, auto);
    aspect-ratio: 16 / 9;

    ${ theme.breakpoints.down('sm') } {
      --hero-section-video-width: 100%;
    }
  `,
  Iframe: css`
    width: 100%;
    height: 100%;
    box-shadow: 0 9px 18px 0 rgba(35, 23, 5, 0.26);
    border: 0;
  `,
}
