import { useContext, useEffect, useState } from 'react';

import { LinkTrackingCtx } from '@/components/uikit/link/TdLink';
import {
  detectOS,
  isMobileOS,
} from '@/utils/download.util';
import trackingUtils from '@/utils/tracking.util';

export const useInitDownloadButton = (downloadInfo: DownloadInfoType) => {
  //value here is just the tab value equivalent to the tab value
  const [value, setValue] = useState(0);
  const [isDesktop, setIsDesktop] = useState(false);
  const tabLabels = ['Windows', 'Mac OS', 'Ubuntu'];
  const trackingCtx = useContext(LinkTrackingCtx);
  useEffect(() => {
    const userOS = detectOS();
    const initialIndex = Object.keys(downloadInfo).indexOf(userOS);
    setIsDesktop(!isMobileOS(userOS));

    if (initialIndex !== -1) {
      setValue(initialIndex);
    }
  }, [downloadInfo]);

  const handleChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
    trackingUtils.linkTracking(null, {
      ...trackingCtx,
      linkName: tabLabels[newValue],
      linkType: 'tab',
      linkUrl: '',
    });
  };

  return { value, isDesktop, handleChange, tabLabels };
};
