import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { storyblokEditable } from '@storyblok/react';
import NextImage from 'next/image';

import TdLink from '@/components/uikit/link/TdLink';
import type { LogoStoryblok } from '@/sb-types';

type UserStyles = Pick<LogoStoryblok, 'sizeObj' | 'positionObj' | 'marginObj' | 'paddingObj' | 'config' | 'zIndex'>

const StyledTdImage = styled(NextImage, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<UserStyles>(
  ({
    zIndex,
    config,
    sizeObj,
    positionObj,
    marginObj,
    paddingObj,
    theme,
  }) => css({
    zIndex,

    ...config,
    ...sizeObj,
    ...positionObj,
    ...marginObj,
    ...paddingObj,
    ...theme.utils.objStyleBreakpoints(config, sizeObj, positionObj, marginObj, paddingObj),
  }),
);
export const LogoBlok: FC<LogoStoryblok> = ({ blok }) => (

  <TdLink
    href={ '/' }
    linkName="logo" linkType="img"
    aria-label="Time Doctor logo"
  >
    <StyledTdImage
      src={ blok.image?.filename || '/_static/svg/td-logo.svg' } width={ blok.image?.meta_data.width || 190 }
      height={ blok.image?.meta_data.height || 37 }
      alt={ 'Time Doctor logo' }
      { ...blok } { ...storyblokEditable(blok) }
    />
  </TdLink>
)
