import type { FC } from 'react';
import { useContext, useState } from 'react';
import { css } from '@emotion/react';
import type { Theme } from '@mui/material/styles';
import type { TabsProps } from '@mui/material/Tabs';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { a11yProps } from '@/components/uikit/basic-tabs/BasicTabs';
import { LinkTrackCtxProvider, LinkTrackingCtx } from '@/components/uikit/link/TdLink';
import TabPanel from '@/components/uikit/tab-panel/TabPanel';
import { ToggleTab, ToggleTabs } from '@/components/uikit/toggle-tabs/ToggleTabs';
import type { ToggleTabsStoryblok } from '@/sb-types';
import type { LinkTrackCtxData } from '@/types/tracking.types';
import trackingUtils from '@/utils/tracking.util';

type StoryBlokToggleProps = {
  blok: SbBlokData<ToggleTabsStoryblok>;
}

type ToggleTabValue = 0 | 1;

const styles = {
  TabsWrapper: css`
    max-width: 1170px;
    margin: 0 auto;
  `,
  Tabs: (theme: Theme) => css`
    margin-bottom: 48px;
    width: 50%;

    ${ theme.breakpoints.down('md') } {
      width: 100%;
    }
  `,
};

export const ToggleTabsBlok: FC<StoryBlokToggleProps> = ({ blok }) => {
  const [tabValue, setTabValue] = useState<ToggleTabValue>(0);
  const inheritedTrackingCtx = useContext(LinkTrackingCtx);

  const handleTabChange: TabsProps['onChange'] = (_e, tabIndex) => {
    setTabValue(tabIndex);
  };

  const handleTabClick = (tabTitle: string) => {
    const trackingCtx: LinkTrackCtxData = {
      ...inheritedTrackingCtx,
      linkName: tabTitle,
      linkType: 'toggle-tab',
    };

    trackingUtils.linkTracking(null, trackingCtx);
  };

  return (
    <div { ...storyblokEditable(blok) }>
      <div css={ styles.TabsWrapper }>
        <ToggleTabs
          value={ tabValue } onChange={ handleTabChange }
          css={ styles.Tabs }
          style={{ marginLeft: blok.align === 'inverted' ? 'auto' : undefined }}
        >
          <ToggleTab
            label={ blok.tabOneTitle }
            onClick={ () => handleTabClick(blok.tabOneTitle) }
            { ...a11yProps(0, `section-2-toggleTabs${ blok._uid }`) }
          />
          <ToggleTab
            label={ blok.tabSecondTitle }
            onClick={ () => handleTabClick(blok.tabSecondTitle) }
            { ...a11yProps(1, `section-2-toggleTabs${ blok._uid }`) }
          />
        </ToggleTabs>
      </div>
      <LinkTrackCtxProvider value={{
        ...inheritedTrackingCtx,
        location: blok.tabOneTrackingLocation || `toggle-tabs-${ blok.tabOneTitle }`,
      }}>
        <TabPanel
          idPrefix={ `section-2-toggleTabs${ blok._uid }` }
          index={ 0 }
          value={ tabValue }>
          { blok.tabOneContent.map(tabBlok => <StoryblokComponent blok={ tabBlok } key={ tabBlok._uid } />) }
        </TabPanel>
      </LinkTrackCtxProvider>
      <LinkTrackCtxProvider value={{
        ...inheritedTrackingCtx,
        location: blok.tabTwoTrackingLocation || `toggle-tabs-${ blok.tabSecondTitle }`,
      }}>
        <TabPanel
          idPrefix={ `section-2-toggleTabs${ blok._uid }` }
          index={ 1 }
          value={ tabValue }>
          { blok.tabSecondContent.map((tabBlok) => <StoryblokComponent blok={ tabBlok } key={ tabBlok._uid } />) }
        </TabPanel>
      </LinkTrackCtxProvider>
    </div>
  );
};
