import type { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { AccordionA11y } from '@/components/uikit/AccordionA11y';
import type { AccordionStoryblok } from '@/sb-types';


type AccordionBlokProps = {
  blok: SbBlokData<AccordionStoryblok>
}

export const AccordionBlok: FC<AccordionBlokProps> = ({ blok }) => (
  <Accordion { ...blok } { ...storyblokEditable(blok) }>
    { blok.content.map((blok) => (
      <StoryblokComponent blok={ blok } key={ blok._uid } />
    )) }
  </Accordion>
);

const Accordion = styled(AccordionA11y)<AccordionStoryblok>((props) => css`
  --accordion-icon-color: ${ props.iconColor?.color };
  --accordion-expanded-bg-color: ${ props.expandedColor?.color };
`);
