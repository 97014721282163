import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { heroSectionStyles } from '@/components/web3/HeroSection/HeroSection.styles';
import type { HeroSectionMediaStoryblok } from '@/sb-types';


type HeroSectionMediaBlokProps = {
  blok: SbBlokData<HeroSectionMediaStoryblok>;
}

type ImageVariantProps = Pick<HeroSectionMediaStoryblok, 'mainImg' | 'graphImg' | 'mediumImg' | 'smallImg'>
type VideoVariantProps = Pick<HeroSectionMediaStoryblok, 'videoUrl'>

type UserStyles = Pick<HeroSectionMediaStoryblok, 'paddingObj' | 'marginObj' | 'sizeObj'>

const ImageVariant: FC<ImageVariantProps> = ({ mainImg, mediumImg, smallImg, graphImg }) => (
  <>
    { mainImg?.map(nestedBlok => (
      <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />
    )) }
    { mediumImg?.map(nestedBlok => (
      <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />
    )) }
    { smallImg?.map(nestedBlok => (
      <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />
    )) }
    { graphImg?.map(nestedBlok => (
      <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />
    )) }
  </>
);

const VideoVariant: FC<VideoVariantProps> = ({ videoUrl }) => (
  <div css={ heroSectionStyles.VideoContainer }>
    <iframe
      css={ heroSectionStyles.Iframe }
      src={ videoUrl?.url }
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen />
  </div>
);

const StyledBox = styled(MuiBox, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<UserStyles>(({ paddingObj, marginObj, sizeObj, theme }) => css({
  position: 'relative',

  ...marginObj,
  ...paddingObj,
  ...sizeObj,

  ...theme.utils.objStyleBreakpoints(paddingObj, marginObj, sizeObj),
}));

export const HeroSectionMediaBlok: FC<HeroSectionMediaBlokProps> = ({ blok: { component, ...restBlok }}) => (
  <StyledBox { ...restBlok } { ...storyblokEditable({ component, ...restBlok }) }>
    { restBlok.variant === 'image' && <ImageVariant { ...restBlok } /> }
    { restBlok.variant === 'video' && <VideoVariant { ...restBlok } /> }
  </StyledBox>
);
