import type { FC, PropsWithChildren } from 'react';
import type { Settings } from 'react-slick';
import Slider from 'react-slick';

import { styles } from './SlickSlider.styles';

type SlickSliderProps = PropsWithChildren<Settings>;

export const SlickSlider: FC<SlickSliderProps> = ({ children, ...sliderSettings }) => (
  <div css={ styles.Root }>
    <Slider { ...sliderSettings }>
      { children }
    </Slider>
  </div>
);
