import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { LinkTrackCtxProvider } from '@/components/uikit/link/TdLink';
import { useAppSelector } from '@/hooks';
import type { SectionStoryblok } from '@/sb-types';

type SectionBlokProps = {
  blok: SbBlokData<SectionStoryblok>;
}

type UserStyles = Pick<SectionStoryblok, 'primaryBg' | 'secondaryBg' | 'bgColor' | 'config' | 'paddingObj' | 'marginObj' | 'sizeObj'>;

const Section = styled('section', {
  shouldForwardProp: (prop) => isPropValid(prop),
})<UserStyles>
(({ primaryBg, secondaryBg, bgColor, config, paddingObj, marginObj, sizeObj, theme }) => {
  const bgStylesResponsive = {
    xl: getBackgroundStyles(primaryBg?.responsive?.xl, secondaryBg?.responsive?.xl),
    lg: getBackgroundStyles(primaryBg?.responsive?.lg, secondaryBg?.responsive?.lg),
    md: getBackgroundStyles(primaryBg?.responsive?.md, secondaryBg?.responsive?.md),
    sm: getBackgroundStyles(primaryBg?.responsive?.sm, secondaryBg?.responsive?.sm),
  };

  return ({
    position: 'relative',

    ...paddingObj,
    ...marginObj,
    ...sizeObj,
    ...bgColor,
    ...config,
    ...getBackgroundStyles(primaryBg, secondaryBg),

    ...theme.utils.objStyleBreakpoints(paddingObj, marginObj, sizeObj, bgColor, config, bgStylesResponsive),
  })
});

export const SectionBlok: FC<SectionBlokProps> = ({ blok }) => {
  const { pageInfo } = useAppSelector(state => state.tracking);

  return (
    <LinkTrackCtxProvider value={{
      category: blok.trackingCategory || pageInfo.category,
      location: blok.trackingLocation || 'section',
    }}>
      <Section { ...blok } { ...storyblokEditable(blok) }>
        { blok.rows.map(rowBlok => <StoryblokComponent blok={ rowBlok } key={ rowBlok._uid } />) }
      </Section>
    </LinkTrackCtxProvider>
  );
};

const getBackgroundStyles = (primaryBgData: BgField | undefined, secondaryBgData: BgField | undefined) => {
  if (!primaryBgData) return undefined;

  const { bgImage, bgGradient, bgRepeat = 'no-repeat', bgSize = '100% auto', bgPosition = 'top left' } = primaryBgData;
  const secBgImage = secondaryBgData?.bgImage?.filename ? `, url(${ secondaryBgData.bgImage.filename })` : '';
  const secBgGradient = secondaryBgData?.bgGradient ? `, ${ secondaryBgData.bgGradient }` : '';
  const secBgRepeat = secondaryBgData?.bgRepeat || 'no-repeat';
  const secBgSize = secondaryBgData?.bgSize || '100% auto';
  const secBgPosition = secondaryBgData?.bgPosition || 'top left';

  return {
    backgroundImage: bgImage?.filename ? `url(${ bgImage.filename })${ secBgImage }` : undefined,
    backgroundRepeat: `${ bgRepeat }, ${ secBgRepeat }`,
    backgroundSize: `${ bgSize }, ${ secBgSize }`,
    backgroundPosition: `${ bgPosition }, ${ secBgPosition }`,
    background: bgGradient ? `${ bgGradient }${ secBgGradient }` : undefined,
  };
};
