import type { FC } from 'react';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent } from '@storyblok/react';

import type { FooterStoryblok } from '@/sb-types';

type FooterBlokProps = {
  blok: SbBlokData<FooterStoryblok>
}

export const FooterBlok: FC<FooterBlokProps> = ({ blok }) => (
  <div>
    {
      blok?.content?.map(footerItem => (
        <StoryblokComponent blok={ footerItem } key={ footerItem._uid } />
      ))
    }
  </div>
);
