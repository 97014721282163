import type { FC } from 'react';
import { css } from '@emotion/react';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { HtmlEmbeddedStoryblok } from '@/sb-types';

type HtmlEmbeddedBlokProps = {
  blok: SbBlokData<HtmlEmbeddedStoryblok>;
}

const styles = {
  Root: css`
    width: 100%;
    height: 100%;
  `,
};


export const HtmlEmbeddedBlok: FC<HtmlEmbeddedBlokProps> = ({ blok }) => (
  <div css={ styles.Root } { ...storyblokEditable(blok) } dangerouslySetInnerHTML={{ __html: blok.html || '' }} />
);
