export type ExpiringItem<T> = {
    value: T;
    expiry: number;
}

export const LocalStorageUtility = {
  setItemWithExpiry<T>(key: string, value: T, expiryInDays: number): void {
    const now = new Date();
    const expiryTime = now.getTime() + (expiryInDays * 24 * 60 * 60 * 1000); // Convert to milliseconds
    const expiringItem: ExpiringItem<T> = { value, expiry: expiryTime };
    localStorage.setItem(key, JSON.stringify(expiringItem));
  },
  getItemWithExpiry<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    if (item) {
      const expiringItem: ExpiringItem<T> = JSON.parse(item);
      const now = new Date();
      if (now.getTime() < expiringItem.expiry) {
        return expiringItem.value;
      } else {
        localStorage.removeItem(key);
      }
    }
    return null;
  },
}

export default LocalStorageUtility;
