import { useEffect } from 'react';

import { deleteUTMCookies, getCookie, parseCookieObj, setCookie } from '@/utils/cookies.util';
import { getParameterByName } from '@/utils/tracking.util';

const loadCookie = (name: string, secure: boolean) => {
  const paramByName = getParameterByName(name);
  if(paramByName) {
    setCookie(`__td_pstack_${ name }`, paramByName, {
      expires: 180,
      path: '/',
      secure,
    });
  }
};

const loadPartnerStackCookies = () => {
  const secure = true;
  const partnerStackCookies: Array<string> = ['gsxid', 'gspk'];
  partnerStackCookies.forEach((cookieName) => {
    loadCookie(cookieName, secure);
  });
};

const cookiesOnLoad = () => {
  const secure = true;

  setCookie('utm_last_source', getParameterByName('utm_source'), {
    expires: 180,
    path: '/',
    secure,
  });
  setCookie('utm_last_medium', getParameterByName('utm_medium'), {
    expires: 180,
    path: '/',
    secure,
  });
  setCookie('utm_last_campaign', getParameterByName('utm_campaign'), {
    expires: 180,
    path: '/',
    secure,
  });

  if (!getCookie('td_referrer')) {
    setCookie('td_referrer', document.referrer, {
      expires: 180,
      path: '/',
      secure,
    });
  }

  const UTMCookies: Array<string> = ['utm_source', 'utm_medium', 'utm_term', 'utm_content', 'utm_campaign'];
  deleteUTMCookies();
  UTMCookies.forEach((cookieName) => {
    loadCookie(cookieName, secure);
  });
};

export const useBaseLayoutState = () => {
  useEffect(() => {
    const onCookieBotAccept = function () {
      if (Cookiebot.consent.marketing) {
        //Execute code that sets marketing cookies
        cookiesOnLoad();
      }
    };
    window.addEventListener('CookiebotOnAccept', onCookieBotAccept, false);

    const cookieConsent = getCookie('CookieConsent');
    if (cookieConsent && parseCookieObj(cookieConsent)?.marketing) {
      cookiesOnLoad();
    }

    loadPartnerStackCookies();

    return () => {
      window.removeEventListener('CookiebotOnAccept', onCookieBotAccept, false);
    };
  }, []);
};
