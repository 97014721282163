import type { ThemeOptions } from '@mui/material';

export const breakpoints: ThemeOptions['breakpoints'] = {
  values: {
    zero: 0,
    xs: 375,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1200,
    xxl: 1366,
  },
};
