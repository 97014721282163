import type { ThemeOptions } from '@mui/material';

export const components: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      disableElevation: true, // By default, we don't use elevation on buttons!
      size: 'large',
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true, // Tabs in TD style guide do not use ripple
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        body: 'p',
        body1: 'p',
        body2: 'p',
        body3: 'p',
        display: 'h2',
      },
    },
  },
  MuiSvgIcon: {
    variants: [
      {
        props: { fontSize: 'extraLarge' },
        style: {
          fontSize: '3rem',
        },
      },
      {
        props: { fontSize: 'large' },
        style: {
          fontSize: '2rem',
        },
      },
    ],
  },
};
