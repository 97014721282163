import type { FC } from 'react';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent } from '@storyblok/react';
import type { StoryblokStory } from 'storyblok-generate-ts';

import type { GlobalReferenceStoryblok, GlobalStoryblok } from '@/sb-types';


type GlobalReferenceBlokProps = {
  blok: SbBlokData<GlobalReferenceStoryblok>;
}

export const GlobalReference: FC<GlobalReferenceBlokProps> = ({ blok }) => {
  const reference = blok.reference as StoryblokStory<GlobalStoryblok>;


  return (
    <>
      { reference?.content?.contentBlocks?.map(contentBlock => (
        <StoryblokComponent blok={ contentBlock } key={ contentBlock._uid } />
      )) }
    </>
  );
};
