import type { FC } from 'react';
import { useCallback } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { Theme } from '@mui/material/styles';
import MuiTypography from '@mui/material/Typography';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { AnimatedTitleStoryblok, TextStoryblok } from '@/sb-types';


type AnimatedTitleBlok = {
  blok: SbBlokData<AnimatedTitleStoryblok>;
}

const style = {
  AnimatedHeading: (theme: Theme) => css`
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    span {
      display: none;
      width: auto;
      text-align: left;

    }

    span[data-displayed='true'] {
      display: inline-block;
      animation: fadeIn 0.7s;
    }

    span[data-displayed='false'] {
      animation: fadeIn 0.5s forwards;
    }

    ${ theme.breakpoints.down('md') } {
      span {
        text-align: center;
      }
    }
  `,
};

type UserStyles = Pick<SbBlokData<TextStoryblok>, 'fontConfig' | 'customColor' | 'colorTitle'>

const propsToForward: (keyof TextStoryblok)[] = ['variant'];

const StyledMuiTypography = styled(MuiTypography, {
  shouldForwardProp: (prop) => isPropValid(prop) || propsToForward.includes(prop as keyof TextStoryblok),
})<UserStyles>
(({ theme, fontConfig, customColor, colorTitle }) => ({

  colorTitle,
  ...fontConfig,
  ...customColor,
  ...theme.utils.objStyleBreakpoints(fontConfig, customColor),
}));

export const AnimatedTitleBlok: FC<AnimatedTitleBlok> = ({ blok }) => (
  <StyledMuiTypography
    colorTitle={ blok.colorTitle }
    variant={ blok.variant || 'h2' } { ...blok } { ...storyblokEditable(blok) }
  >
    { blok.animationPosition === 'start' && (
      <>
        { <AnimatedHeading
          animatedTitleOptions={ blok.animatedTitleOptions } duration={ blok.duration }
          colorTitle={ blok.colorTitle } _uid={ '1' } component={ 'animated_title' } /> }
        { blok.content }
      </>
    ) }
    { blok.animationPosition === 'center' && (
      <>
        { blok.content }
        { <AnimatedHeading
          animatedTitleOptions={ blok.animatedTitleOptions } duration={ blok.duration }
          colorTitle={ blok.colorTitle } _uid={ '1' } component={ 'animated_title' } /> }
        { blok.endContent }
      </>
    ) }
    { blok.animationPosition === 'end' && (
      <>
        { blok.content }
        { <AnimatedHeading
          animatedTitleOptions={ blok.animatedTitleOptions } duration={ blok.duration }
          colorTitle={ blok.colorTitle } _uid={ '1' } component={ 'animated_title' } /> }
      </>
    ) }
  </StyledMuiTypography>
)

const AnimatedHeading: FC<AnimatedTitleStoryblok> = ({ duration, colorTitle, animatedTitleOptions }) => {
  const onTitleLoad = useCallback((node: HTMLElement | null) => {
    if (node != null) {
      const allSpans = node.querySelectorAll('span');

      if (allSpans.length) {
        setInterval(() => {
          for (const [i, span] of allSpans.entries()) {
            if (span.getAttribute('data-displayed') === 'true') {
              const nextSpan = allSpans.length - 1 === i ? 0 : i + 1;
              span.setAttribute('data-displayed', 'false');
              allSpans[nextSpan].setAttribute('data-displayed', 'true');

              break;
            }
          }
          // eslint-disable-next-line no-magic-numbers
        }, Number(duration) * 1000 || 3000);
      }
    }
  }, []);

  const animatedTitleOptionsArray = animatedTitleOptions?.split(/\s*\|\s*/);

  return (
    <span style={{ color: `${ colorTitle }` }} ref={ onTitleLoad } css={ style.AnimatedHeading }>
      {
        animatedTitleOptionsArray?.map((text, index) => (
          <span key={ index } data-displayed={ index === 0 }>{ text }</span>
        ))
      }
    </span>
  );
};
