import axios from 'axios';

import type { GeoInfoResponse } from '@/pages/api/geo-info';
import { LocalStorageUtility } from '@/utils/localstorage.util';

type PlatformData = {
  name: string;
  value: string;
  version: string;
}

type StoredGeoInfo = {
  ip: string;
  countryCode: string;
}

const deviceDetector = {
  options: [],
  header: () => [
    navigator?.platform,
    navigator?.userAgent,
    navigator?.appVersion,
    navigator?.vendor,
    window['opera'],
  ],
  dataos: [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' },
  ],
  getGeoLocData: async (): Promise<{ ip: string; countryCode: string; }> => {
    try {
      const geoInfoKey = 'geo-info';
      let storedGeoInfo: StoredGeoInfo | null = LocalStorageUtility.getItemWithExpiry(geoInfoKey);
      if (storedGeoInfo) return storedGeoInfo;

      const { data: responseData } = await axios.get<GeoInfoResponse>(`${ process.env.NEXT_PUBLIC_VERCEL_HOST_URL }/api/geo-info`);
      storedGeoInfo = {
        ip: responseData.ip,
        countryCode: responseData.geo.country,
      };
      LocalStorageUtility.setItemWithExpiry(geoInfoKey, storedGeoInfo, 7);
      return storedGeoInfo;
    } catch (e) {
      return {
        ip: 'unknown',
        countryCode: 'unknown',
      };
    }
  },
  countryCode: '',
  ipAddr: '',
  isMobile: () => window.matchMedia('(hover: none), (pointer: coarse)').matches
    && (/Android|Iphone|HarmonyOS/i).test(window.navigator.userAgent),
  initialize: async function () {
    const agent = this.header().join(' ');
    const os = this.matchDevice(agent, this.dataos);
    const { userAgent } = window.navigator;
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    const height = (window.innerHeight > 0) ? window.innerHeight : screen.height;
    const ismobile = this.isMobile();

    if (!this.ipAddr || !this.countryCode) {
      const { ip, countryCode } = await this.getGeoLocData();

      // Cache the geo data so that we don't retrieve the IP for every track request
      this.ipAddr = ip;
      this.countryCode = countryCode;
    }

    return {
      width,
      height,
      deviceinfo: { os, browser: this.browserDetectionName(userAgent) },
      ismobile,
      ipaddress: this.ipAddr,
      country: this.countryCode,
    };
  },
  matchDevice: function (agent: string, data: PlatformData[]) {
    for (const datum of data) {
      const regex = new RegExp(datum.value, 'i');
      const match = regex.test(agent);

      if (match) {
        return datum.name;
      }
    }

    return 'unknown';
  },

  browserDetectionName: function (userAgent: string) {
    const browserName = (regexp: RegExp) => regexp.test(userAgent);

    switch (true) {
      case browserName(/edg/i):
        return 'Microsoft Edge';
      case browserName(/trident/i):
        return 'Microsoft Internet Explorer';
      case browserName(/firefox|fxios/i):
        return 'Mozilla Firefox';
      case browserName(/opr\//i):
        return 'Opera';
      case browserName(/ucbrowser/i):
        return 'UC Browser';
      case browserName(/samsungbrowser/i):
        return 'Samsung Browser';
      case browserName(/chrome|chromium|crios/i):
        return 'Google Chrome';
      case browserName(/safari/i): return 'Apple Safari';
      default: return 'Other';
    }
  },
};

export default deviceDetector;
