import type { FC } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { GenericTableStoryblok } from '@/sb-types';


type TableBlokProps = {
  blok: SbBlokData<GenericTableStoryblok>
}


export const TableBlok: FC<TableBlokProps> = ({ blok }) => (
  <div
    { ...storyblokEditable(blok) }
    { ...blok }>
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            { blok.content?.thead.map(({ value }, index) => (
              <TableCell key={ index + 1 }>
                <MuiTypography variant="h6">
                  { value }
                </MuiTypography>
              </TableCell>
            )) }
          </TableRow>

        </TableHead>
        <TableBody>
          { blok.content?.tbody.map((bodyContent, index) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
              key={ index + 1 }>
              { bodyContent.body.map(({ value }, index) => (
                <TableCell
                  align="left"
                  key={ index + 1 }>
                  <MuiTypography variant="body2">
                    { value }
                  </MuiTypography>
                </TableCell>
              )) }
            </TableRow>
          )) }
        </TableBody>
      </Table>
    </TableContainer>
  </div>

);
