import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import type { CommonCssStyles, RowStoryblok } from '@/sb-types';

type RowBlokProps = {
  blok: SbBlokData<RowStoryblok>;
}

type UserStyles = Pick<RowStoryblok, 'marginObj' | 'paddingObj' | 'gapObj' | 'sizeObj' | 'config' | 'bgColor' | 'isSticky' | 'isStickyBottom'>

const StyledBox = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})<UserStyles>(({
  marginObj,
  paddingObj,
  gapObj,
  sizeObj,
  config,
  bgColor,
  isSticky,
  isStickyBottom,
  theme,
}) => {
  const maxWidthObj = {
    maxWidth: 'calc(100% - 64px)',
    md: { maxWidth: 'calc(100% - 48px)' },
    sm: { maxWidth: 'calc(100% - 32px)' },
  };

  const stickyStyles = isSticky ? {
    position: 'sticky',
    top: '134px',
    zIndex: '1',
    backgroundColor: 'white',
  } : {};

  const stickyBottomStyles = isStickyBottom ? {
    position: 'sticky',
    bottom: '5px',
    zIndex: '1',
    backgroundColor: 'white',
  } : {};

  return css({
    margin: '0 auto',
    width: '1170px',

    ...stickyStyles as CommonCssStyles,
    ...stickyBottomStyles as CommonCssStyles,
    ...maxWidthObj,

    ...marginObj,
    ...paddingObj,
    ...sizeObj,
    ...config,
    ...gapObj,
    ...bgColor,

    ...theme.utils.objStyleBreakpoints(maxWidthObj, marginObj, paddingObj, sizeObj, config, gapObj, bgColor),
  })
});

export const RowBlok: FC<RowBlokProps> = ({ blok: { component, ...allProps }}) => (
  <StyledBox
    { ...allProps } { ...storyblokEditable({ component, ...allProps }) }>
    { allProps.content?.map(columnBlok => <StoryblokComponent blok={ columnBlok } key={ columnBlok._uid } />) }
  </StyledBox>
);
