import type React from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import classNames from 'classnames';
import type { Property } from 'csstype';

import FlexBox from '@/components/uikit/flex-box/FlexBox';
import RenderIf from '@/shared/render-if/RenderIf';

import styles from './ReviewBadge.module.scss';

type ReviewBadgeProps = {
  badgeColor?: Property.Color;
  starColor?: Property.Color;
  className?: string;

  /**
   * Number of stars to render. Use `f`, for full star and `h` for half start. By default, this is `ffffh`.
   *
   * @example `fffh` will render 3 full stars and half star
   */
  stars?: string;

  /** Svg that will be rendered as part of the badge. */
  children?: React.ReactNode;
}

const ReviewBadge: React.FC<ReviewBadgeProps> = (
  {
    badgeColor = '#6d6f78',
    stars = 'ffffh',
    starColor,
    className,
    children,
    ...allProps
  },
) => (
  <figure
    className={ classNames(styles.ReviewBadge, className) }
    style={{ '--badge-color': badgeColor } as React.CSSProperties}
    { ...allProps }
  >
    <FlexBox>
      { Array.from(stars).map((star, i) => (
        <div key={ i }>
          {
            star === 'f'
              ? (<StarIcon key={ `svg-star-${ i }` } sx={{ color: starColor }} className={ styles.StarIcon } />)
              : (<StarHalfIcon key={ `svg-star-${ i }` } sx={{ color: starColor }} className={ styles.StarIcon } />)
          }
        </div>
      )) }
    </FlexBox>
    <RenderIf condition={ !!children }>
      <div className={ styles.SvgWrap }>
        { children }
      </div>
    </RenderIf>
  </figure>
);

export default ReviewBadge;
