import type { FC } from 'react';
import { useState } from 'react';
import { css } from '@emotion/react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import type { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent } from '@storyblok/react';

import { useScreenResize } from '@/hooks';
import type { FooterNavColumnStoryblok } from '@/sb-types';
import RenderIf from '@/shared/render-if';

type FooterNavColBlokProps = {
  blok: SbBlokData<FooterNavColumnStoryblok>
}

const stylesNavColumn = {
  UL: css`
    padding: 0;
    margin: 0;
  `,
  ButtonNav: (theme: Theme) => css`
    display: flex;
    @media (max-width: ${ theme.breakpoints.values.sm }px) {
      justify-content: space-between;
    }
  `,
  ExpandMore: (theme: Theme) => css`
    display: none;
    width: 24px;
    height: 24px;
    padding: 0;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;

    @media (max-width: ${ theme.breakpoints.values.sm }px) {
      display: block;
      [data-expanded="true"] & {
        transform: rotate(180deg);
      }
    }
  `,
}


export const FooterNavColBlok: FC<FooterNavColBlokProps> = ({ blok }) => {
  const { width } = useScreenResize();
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  function onAccordionHeaderClick() {
    setIsExpanded(!isExpanded);
  }

  return (
    <div>
      <nav>
        <div
          css={ stylesNavColumn.ButtonNav }
          data-expanded={ isExpanded }
          data-testid={ `footer-accordion-header-${ blok.title?.toLowerCase() }` }
          onClick={ onAccordionHeaderClick }>
          {
            blok?.titleText?.map(footerNavItem => (
              <StoryblokComponent blok={ footerNavItem } key={ footerNavItem._uid } />
            ))
          }
          <Button
            type="button"
            css={ stylesNavColumn.ExpandMore }>
            <ExpandMore data-expanded={ isExpanded } sx={{ fontSize: 24 }} />
          </Button>
        </div>

        <RenderIf condition={ width > theme.breakpoints.values.sm }>
          <ul
            css={ stylesNavColumn.UL }>
            {
              blok?.navItem?.map(footerNavItem => (
                <StoryblokComponent blok={ footerNavItem } key={ footerNavItem._uid } />
              ))
            }
          </ul>
        </RenderIf>

        <RenderIf condition={ isExpanded && width < theme.breakpoints.values.sm }>
          <ul
            css={ stylesNavColumn.UL }>
            {
              blok?.navItem?.map(footerNavItem => (
                <StoryblokComponent blok={ footerNavItem } key={ footerNavItem._uid } />
              ))
            }
          </ul>
        </RenderIf>

      </nav>
    </div>
  );
};
