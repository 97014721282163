import * as Sentry from '@sentry/browser';
import type { AxiosResponse } from 'axios';

import deviceDetector from '@/utils/device-detector.util';

import { dataIntegrityCall } from '../requests/track.api';
import type {
  AnalyticsPagePayload,
  AnalyticsTrackPayload,
  LinkTrackCtxData,
  TrackApiPayload,
  TrackExperimentProps,
} from '../types';

import cookiesUtilities, { getCookie } from './cookies.util';

export interface ITrackService {
  trackPage: (data: AnalyticsPagePayload) => Promise<void | AxiosResponse>;
  trackEvent: (data: AnalyticsTrackPayload) => Promise<void | AxiosResponse>;
}

export class ConsoleTrackService implements ITrackService {
  public trackPage(data: AnalyticsPagePayload): Promise<void> {
    if (!data?.name) return Promise.resolve();
    // eslint-disable-next-line no-console
    console.log('TRACK PAGE', data);
    return Promise.resolve();
  }

  public trackEvent(data: AnalyticsTrackPayload): Promise<void> {
    if (includeEmail(data?.event)) {
      if (data?.properties?.email) {
        data.properties.formemail = data.properties.email;
        data.properties.email = null;
      }
      const loggedUser = getLoggedUserData();
      const email = loggedUser?.email || null;
      if (email && data?.properties) data.properties.email = email;
    }
    // eslint-disable-next-line no-console
    console.log('TRACK EVENT', data);
    return Promise.resolve();
  }
}

export class ServerTrackService implements ITrackService {
  public async trackEvent(trackingData: AnalyticsTrackPayload): Promise<AxiosResponse> {
    if (includeEmail(trackingData?.event)) {
      if (trackingData?.properties?.email) {
        trackingData.properties.formemail = trackingData.properties.email;
        trackingData.properties.email = null;
      }
      const loggedUser = getLoggedUserData();
      const email = loggedUser?.email || null;
      if (email && trackingData?.properties) trackingData.properties.email = email;
    }

    window.analytics.track(
      trackingData.event,
      trackingData.properties,
      {
        anonymousId: uuid(),
      },
    );

    const apiPayload: TrackApiPayload = {
      ...trackingData,
      anonymousId: uuid(),
      userId: userId(),
      segmentanonymousid: segmentAnonymousId(),
      type: 'track',
      properties: {
        ...trackingData.properties,
        ...await deviceDetector.initialize(),
      },
      context: getContext(),
    };

    return dataIntegrityCall(apiPayload);
  }

  public async trackPage(trackingData: AnalyticsPagePayload): Promise<AxiosResponse | void> {
    if (!trackingData?.name) return Promise.resolve();
    window.analytics.page(
      trackingData.category,
      trackingData.name,
      {
        ...trackingData.properties,
        path: window.location.pathname,
        title: window.document.title,
        url: window.location.href,
      },
      {
        anonymousId: uuid(),
      },
    );

    const apiPayload: TrackApiPayload = {
      anonymousId: uuid(),
      userId: userId(),
      segmentanonymousid: segmentAnonymousId(),
      type: 'page',
      properties: {
        ...trackingData.properties,
        ...await deviceDetector.initialize(),
        path: window.location.pathname,
        title: window.document.title,
        url: window.location.href,
        referrer: window.document.referrer,
      },
      category: trackingData.category,
      name: trackingData.name,
      context: getContext(),
    };

    return dataIntegrityCall(apiPayload);
  }
}

export class TrackService {
  static getInstance(): ITrackService {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'development') {
      return new ConsoleTrackService();
    } else {
      return new ServerTrackService();
    }
  }
}

export function getLoggedUserData() {
  try {
    return JSON.parse(atob(cookiesUtilities.getCookie('__tdudft')));
  } catch {
    return {};
  }
}
export function uuid() {
  let uuid = cookiesUtilities.getCookie('td_anonymousId');
  if (!uuid) {
    let dt = new Date().getTime();
    uuid = 'TD-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
    cookiesUtilities.setCookie('td_anonymousId', uuid);
  }
  return uuid;
}

export function userId() {
  const loggedUser = getLoggedUserData();

  return loggedUser?.userId || null;
}

export function userEmail() {
  const loggedUser = getLoggedUserData();

  return loggedUser?.email || null;
}

export function userCompanyId() {
  const loggedUser = getLoggedUserData();

  return loggedUser?.companyId || null;
}

export function segmentAnonymousId() {
  const stdId = cookiesUtilities.getCookie('ajs_anonymous_id');
  return stdId
    ? stdId.replace(/['"]+/g, '')
    : '';
}

export function getContext() {
  return {
    campaign: {
      source: getParameterByName('utm_source') || getCookie('utm_source'),
      medium: getParameterByName('utm_medium') || getCookie('utm_medium'),
      name: getParameterByName('utm_campaign') || getCookie('utm_campaign'),
      term: getParameterByName('utm_terms') || getParameterByName('utm_term') || getCookie('utm_term'),
      content: getParameterByName('utm_content') || getCookie('utm_content'),
    },
    page: {
      path: window.location.pathname,
      referrer: window.document.referrer,
      search: window.location.search ?? null,
      title: window.document.title,
      url: window.location.href,
    },
    groupId: userCompanyId(),
  };
}
export function getParameterByName(name: string, url: string = window?.location.href): string | null {
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function trackExperiment(props: TrackExperimentProps) {
  const trackedName = 'tracked_' + props.experimentid.replace('-', '');
  const trackedCookie = cookiesUtilities.getCookie(trackedName);

  if (trackedCookie !== 'true') {
    trackingUtils.trackEvent({
      event: EVENT_NAMES.EXPERIMENT_VIEWED,
      properties: {
        ...props,
        trackedon: window.location.href,
      },
    }).then()
      .catch(
        (error) => {
          Sentry.captureMessage('Failed to track experiment:', {
            extra: {
              error,
              level: 'warning',
            },
          });
        },
      );
    cookiesUtilities.setCookie(trackedName, 'true');
  }
}

/**
 *
 * @param anchorElm The anchor element that fires the click event. When set to `null` make
 *  sure you set `trackingCtx.linkName` and `trackingCtx.linkUrl`
 * @param trackingCtx The context data to populate the tracking info and properties.
 */
export const linkTracking = (anchorElm: HTMLAnchorElement | null, trackingCtx: LinkTrackCtxData) => {
  const { location, category, linkName, linkUrl, linkType } = trackingCtx;
  const textFromAnchorElm = anchorElm?.getAttribute('aria-label') ?? anchorElm?.textContent;
  const trackingName = linkName ?? textFromAnchorElm ?? '';
  const disabledTracking = anchorElm?.dataset?.disableTracking;
  const finalLinkUrl = linkUrl ?? anchorElm?.href;

  if (location && category && !disabledTracking) {
    trackingUtils.trackEvent({
      event: EVENT_NAMES.GENERAL.CLICKED_LINK,
      category,
      properties: {
        currenturl: window.location.href,
        ...(finalLinkUrl ? { linkurl: finalLinkUrl } : undefined),
        linktype: linkType,
        linklocation: location,
        linkname: trackingName.replace(/[\r\n]+/g, ''),
        pagename: window.document.title,
      },
    }).then()
      .catch((error) => {
        Sentry.captureMessage('Failed to track link:', {
          extra: {
            error,
            level: 'warning',
          },
        });
      });
  }
};

const trackPage = (trackData: AnalyticsPagePayload) => TrackService.getInstance().trackPage(trackData);

const trackEvent = (trackData: AnalyticsTrackPayload) => TrackService.getInstance().trackEvent(trackData);

export const EVENT_NAMES = {
  HOMEPAGE_CLICKED_CTA: 'Clicked HomePage CTA',
  HOMEPAGE_CLICKED_LINK: 'Clicked Home Page Link',
  HOMEPAGE_CLICKED_SIGN_IN: 'Clicked Sign-in Link',
  HOMEPAGE_FORM_ENGAGED: 'HomePage Form Engaged',
  HOMEPAGE_CLICKED_GOOGLE_AUTH_BTN: 'Clicked Google Auth Button',
  SCROLLED_PAGE: 'Scrolled Page',
  SOLUTION_PAGE: 'SolutionPage',
  CLICKED_BUTTON: 'Button Click',
  PRICING: {
    CLICKED_LINK: 'Link Click',
    CLICKED_PRICING_PLAN_CTA: 'Clicked PricingPage CTA',
    FORM_ENGAGED: 'PricingPage Form Engaged',
  },
  GOOGLE_AUTH: {
    SIGN_IN: 'Signin with GoogleAuth Button',
    SIGN_UP: 'Signup with GoogleAuth Button',
    SUCCESSFUL_AUTH: 'Successful Google Authentication',
    FAIL_TOKEN_CHECK: 'Failed to verify Google Token',
  },
  GENERAL: {
    CLICKED_LINK: 'Link Click',
    SIGNUP_UNSUCCESSFUL: 'Unsuccessful Signup',
  },
  EXPERIMENT_VIEWED: 'Experiment Viewed',
  CLICKED_CTA: 'Clicked CTA',
  NON_HOMEPAGE: {
    FORM_ENGAGED: 'NonHomePage Form Engaged',
    CLICKED_CTA: 'Clicked NonHomePage CTA',
  },
  REQUEST_DEMO: 'Request Demo',
  NEVERBOUNCE: {
    VALIDATION: 'Neverbounce Email Validation',
    FAILED: 'Neverbounce Email Validation Failed',
  },
  CONTACT_US: {
    FORM_SUBMITTED: 'Contact Sales Request',
  },
  HS_FORM: {
    FORM_SUBMITTED: 'HS Form Submitted',
  },
};


export function includeEmail(eventName: string) {
  const withEmailEvents = [
    EVENT_NAMES.PRICING.CLICKED_LINK,
    EVENT_NAMES.SCROLLED_PAGE,
    EVENT_NAMES.HOMEPAGE_CLICKED_CTA,
    EVENT_NAMES.HOMEPAGE_FORM_ENGAGED,
    EVENT_NAMES.GENERAL.SIGNUP_UNSUCCESSFUL,
    EVENT_NAMES.HOMEPAGE_CLICKED_SIGN_IN,
    EVENT_NAMES.HOMEPAGE_CLICKED_LINK,
    EVENT_NAMES.PRICING.FORM_ENGAGED,
    EVENT_NAMES.PRICING.CLICKED_PRICING_PLAN_CTA,
    EVENT_NAMES.EXPERIMENT_VIEWED,
    EVENT_NAMES.HOMEPAGE_CLICKED_GOOGLE_AUTH_BTN,
    EVENT_NAMES.NON_HOMEPAGE.FORM_ENGAGED,
    EVENT_NAMES.NON_HOMEPAGE.CLICKED_CTA,
  ];
  return withEmailEvents.includes(eventName);
}

const trackingUtils = {
  linkTracking,
  trackEvent,
  trackPage,
};

export default trackingUtils;

