import type React from 'react';
import { createContext, useContext } from 'react';

import type { LinkTrackCtxData } from '@/types/tracking.types';
import trackingUtils from '@/utils/tracking.util';

type TdLinkProps = {
  href?: string;
  children?: React.ReactNode;
  linkName?: string;
  linkType?: 'text' | 'button' | 'img';
  enableTrack?: boolean;
  inNewTab?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

export const LinkTrackingCtx = createContext<LinkTrackCtxData>({
  location: '',
  category: '',
});

export const LinkTrackCtxProvider = LinkTrackingCtx.Provider;

const TdLink: React.FC<TdLinkProps> = (
  {
    children,
    linkName,
    linkType = 'text',
    href,
    enableTrack = false,
    inNewTab,
    ...allAttr
  },
) => {
  const trackingCtx = useContext(LinkTrackingCtx);

  const handleClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    if (href || enableTrack) {
      const overrideCtx = {
        ...trackingCtx,
        linkName,
        linkType,
      };

      trackingUtils.linkTracking(evt.currentTarget, overrideCtx);
    }

    if (allAttr.onClick) allAttr.onClick(evt);
  };

  return (
    <a
      href={ href } target={ inNewTab ? '_blank' : undefined } rel={ inNewTab ? 'noreferrer' : undefined } { ...allAttr }
      onClick={ handleClick } onAuxClick={ handleClick }>
      { children }
    </a>
  );
};

export default TdLink;
