import type { FC } from 'react';
import { useFeature } from '@growthbook/growthbook-react';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent } from '@storyblok/react';

import type { AbTestStoryblok } from '@/sb-types';
import type { ExperimentProps } from '@/types/tracking.types';

type ABTestingBlokProps = {
  blok: SbBlokData<AbTestStoryblok>;
}

export const ABTestingBlok: FC<ABTestingBlokProps> = ({ blok }) => {
  const feature = useFeature<ExperimentProps>(blok.experimentId);

  return (
    <>
      { feature?.value?.variationid === 'A' &&
        blok.aContent.map(nestedBlok => <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />)
      }
      { feature?.value?.variationid === 'B'
        && blok.bContent.map(nestedBlok => <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />) }
    </>
  );
};
