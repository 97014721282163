import { useEffect } from 'react';
import { debounce as _debounce } from 'lodash';

import trackingUtils, { EVENT_NAMES } from '@/utils/tracking.util';

export const useScrollHandler = (page: string) => {
  useEffect(() => {
    const scrollHandler = _debounce(() => {
      const { body } = window.document;
      const scrollDepth = window.scrollY / (body.scrollHeight - window.innerHeight) * 100;

      trackingUtils.trackEvent({
        category: page,
        event: EVENT_NAMES.SCROLLED_PAGE,
        properties: {
          scrolldepth: Math.round(scrollDepth),
          pagename: window.document.title,
        },
      });
    }, 200);

    document.addEventListener('scroll', scrollHandler);

    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  });
};
