import { apiPlugin, storyblokInit } from '@storyblok/react';

import { storyblokComponents } from './components';

export const initStoryblok = () => storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_API_TOKEN,
  use: [apiPlugin],
  apiOptions: {
    region: process.env.NEXT_STORY_BLOK_REGION,
  },
  components: storyblokComponents,
});
