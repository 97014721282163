export const shadows = [
  'none',
  '0px 0px 50px 0px rgba(44, 46, 60, 0.15);',
  '0px 13px 30px 0px rgba(0, 0, 0, 0.20)',
  '0px 3px 10px rgba(0, 0, 0, 0.2)',
  '0px 4px 12px rgba(0, 0, 0, 0.22)',
  '0px 5px 15px rgba(0, 0, 0, 0.23)',
  '0px 6px 18px rgba(0, 0, 0, 0.25)',
  '0px 7px 20px rgba(0, 0, 0, 0.27)',
  '0px 8px 22px rgba(0, 0, 0, 0.28)',
];
