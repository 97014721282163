import type React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import type { Theme } from '@mui/material/styles';
import type { Breakpoints } from '@mui/system/createTheme/createBreakpoints';
import type { Property } from 'csstype';

import { withRenderIf } from '@/shared/render-if';

type FlexBoxCSSProperties = {
  /* Flex box properties */
  alignItems?: Property.AlignItems;
  flex?: Property.Flex;
  flexFlow?: Property.FlexFlow;
  gap?: Property.Gap<string | number>;
  justifyContent?: Property.JustifyContent;
  columnGap?: Property.ColumnGap<string | number>;
  rowGap?: Property.RowGap<string | number>;
  mt?: Property.MarginTop<string | number>;
  mb?: Property.MarginBottom<string | number>;
  mr?: Property.MarginRight<string | number>;
  ml?: Property.MarginLeft<string | number>;
  pt?: Property.PaddingTop<string | number>;
  pb?: Property.PaddingBottom<string | number>;
  pr?: Property.PaddingRight<string | number>;
  pl?: Property.PaddingLeft<string | number>;
}

type FlexBoxProps = {
  as?: keyof HTMLElementTagNameMap;

  /* Attributes */
  className?: string;
  attributes?: React.InsHTMLAttributes<unknown>

  // Children of the box
  children?: React.ReactNode;
} & FlexBoxCSSProperties & React.CSSProperties

const FlexBox: React.FC<FlexBoxProps> = (
  {
    as = 'div',
    children,
    className,
    mt, mb, pt, pb,
    attributes,
    ...all
  },
) => {
  const FlexBoxRoot = `${ as }` as keyof JSX.IntrinsicElements;

  return (
    <FlexBoxRoot
      { ...attributes }
      className={ className }
      style={{
        display: 'flex',
        marginTop: mt,
        marginBottom: mb,
        paddingTop: pt,
        paddingBottom: pb,
        ...all,
      }}>
      { children }
    </FlexBoxRoot>
  );
};

export default FlexBox;


type ResponsiveFlexWrap = `${ keyof Breakpoints['values'] }Wrap`;
type ResponsiveFlex = `${ keyof Breakpoints['values'] }Flex`;
type ResponsiveColumnGap = `${ keyof Breakpoints['values'] }ColumnGap`;
type ResponsiveRowGap = `${ keyof Breakpoints['values'] }RowGap`;

type ResponsiveFlexWrapProps = {
  [key in ResponsiveFlexWrap]?: boolean;
}

type ResponsiveFlexProps = {
  [key in ResponsiveFlex]?: Property.Flex;
}

type ResponsiveColumnGapProps = {
  [key in ResponsiveColumnGap]?: Property.ColumnGap<string | number>;
}

type ResponsiveRowGapProps = {
  [key in ResponsiveRowGap]?: Property.RowGap<string | number>;
}

type ResponsiveProps = ResponsiveFlexWrapProps & ResponsiveFlexProps & ResponsiveColumnGapProps & ResponsiveRowGapProps;

export type FlexBoxV2Props = {
  column?: boolean;
  wrap?: boolean;
  maxWidth?: Property.MaxWidth<string | number>;
  height?: Property.Height<string | number>;
  width?: Property.Width<string | number>;
} & FlexBoxCSSProperties & ResponsiveProps

const FlexBoxV2Base = styled('div', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'wrap',
})<FlexBoxV2Props>(({
  column,
  rowGap,
  columnGap,
  flex,
  justifyContent,
  alignItems,
  maxWidth,
  width,
  height,
  theme,
  wrap,
  ...restProps
}) => ({
  display: 'flex',
  flexDirection: column ? 'column' : undefined,
  flexWrap: wrap ? 'wrap' : undefined,
  justifyContent,
  alignItems,
  columnGap: theme.utils.spacing(columnGap),
  rowGap: theme.utils.spacing(rowGap),
  flex,
  marginTop: theme.utils.spacing(restProps.mt),
  marginBottom: theme.utils.spacing(restProps.mb),
  marginRight: theme.utils.spacing(restProps.mr),
  marginLeft: theme.utils.spacing(restProps.ml),
  paddingTop: theme.utils.spacing(restProps.pt),
  paddingBottom: theme.utils.spacing(restProps.pb),
  paddingRight: theme.utils.spacing(restProps.pr),
  paddingLeft: theme.utils.spacing(restProps.pl),
  maxWidth,
  width,
  height,
  ...getResponsiveStyles(restProps, theme),
}));

export const FlexBoxV2 = withRenderIf(FlexBoxV2Base);

export const getResponsiveStyles = (props: ResponsiveProps, theme: Theme) => theme.breakpoints.keys.reduce((prev, next) => {
  const propFlexWrapKey: ResponsiveFlexWrap = `${ next }Wrap`;
  const propFlexKey: ResponsiveFlex = `${ next }Flex`;
  const propColumnGapKey: ResponsiveColumnGap = `${ next }ColumnGap`;
  const propRowGapKey: ResponsiveRowGap = `${ next }RowGap`;

  return {
    ...prev,
    [theme.breakpoints.down(next)]: {
      flexWrap: props[propFlexWrapKey] ? 'wrap' : undefined,
      flex: props[propFlexKey],
      columnGap: theme.utils.spacing(props[propColumnGapKey]),
      rowGap: theme.utils.spacing(props[propRowGapKey]),
    },
  };
}, {});

