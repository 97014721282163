import type { FilledInputProps } from '@mui/material/FilledInput';
import FilledInput from '@mui/material/FilledInput';
import { styled } from '@mui/material/styles';

const TdFilledInput = styled(FilledInput, {})<FilledInputProps>(() => ({
  backgroundColor: 'var(--td-filled-input-bg-color, rgba(237, 238, 242, 0.3))',
  '& input': {
    transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms !important',
  },
  '& input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 50px var(--td-filled-input-bg-color,#fafafb) inset',
  },
  '&:hover input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 50px #e8e8e8 inset',
  },
  '&.Mui-focused input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 50px #f0f0f0 inset',
  },
}));

export default TdFilledInput;
