import { css } from '@emotion/react';
import type { Theme } from '@mui/material/styles';

export const styles = {
  Subtitle: (theme: Theme) => css`
    ${ theme.breakpoints.down('md') } {
      max-width: 640px;
      letter-spacing: -0.05rem;
    }

    ${ theme.breakpoints.down('sm') } {
      max-width: 440px;
    }
  `,
  AnimatedHeading: (theme: Theme) => css`
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    span {
      display: none;
      min-width: 245px;
      text-align: left;
    }

    span[data-displayed='true'] {
      display: inline-block;
      animation: fadeIn 0.7s;
    }

    span[data-displayed='false'] {
      animation: fadeIn 0.5s forwards;
    }

    ${ theme.breakpoints.down('md') } {
      span {
        text-align: center;
      }
    }
  `,
};
