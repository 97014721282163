import type React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import type { TdInputProps } from '@/components/uikit/Input';
import ControlledInput from '@/components/uikit/Input/ControlledInput';
import { sharedInputProps, TdFormControl } from '@/components/uikit/Input/PrivateComponents/TdFormControl';
import type { SignUpFormData } from '@/types/signup.types';

export const TdFormControlPassword: React.FC<TdInputProps> = (props) => {
  const { watch, getFieldState, formState: { errors }} = useFormContext<SignUpFormData>();
  const watchValue = watch('password');
  const { invalid } = getFieldState('password');

  const [hasPasswordReveal, setHasPasswordReveal] = useState(false);
  const [iconColor, setIconColor] = useState<'error' | 'primary' | undefined>(undefined);

  useEffect(() => {
    const errorColor = errors.password?.message && 'error';
    const validColor = !!watchValue && !invalid && 'primary';
    setIconColor(errorColor || validColor || undefined);
  }, [errors.password?.message, invalid, watchValue]);


  const handleClickShowPassword = () => setHasPasswordReveal(prevState => !prevState);

  return (
    <TdFormControl
      variety="password"
      rounded={ props.rounded }
      labelMode={ props.labelMode }
      errorDisplay={ props.errorDisplay }
      render={ ({ field }) => (
        <ControlledInput
          { ...field }
          { ...sharedInputProps(props) }
          variety="password"
          variant={ props.variant }
          autoComplete="current-password"
          id={ props.inputId } type={ hasPasswordReveal ? 'text' : 'password' }
          aria-errormessage="err-password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={ handleClickShowPassword }
                edge="end"
              >
                { hasPasswordReveal ? <Visibility color={ iconColor } /> : <VisibilityOff color={ iconColor } /> }
              </IconButton>
            </InputAdornment>
          }
        />)
      }
      { ...props }
    />
  );
};
