import type { AllowedColors } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { TabProps } from '@mui/material/Tab';
import Tab from '@mui/material/Tab';
import type { TabsProps } from '@mui/material/Tabs';
import Tabs from '@mui/material/Tabs';


type ToggleTabsProps = Omit<TabsProps, 'color'> & {
  color?: keyof AllowedColors;
}

type ToggleTabProps = Omit<TabProps, 'color'> & {
  color?: keyof AllowedColors;
}

export const ToggleTabs = styled(Tabs)<ToggleTabsProps>(({ theme, color }) => ({
  minHeight: '46px',
  '& .MuiTabs-scroller': {
    backgroundColor: theme.palette[color || 'neutral'].dark,
    borderRadius: '100px',
    flex: '0 0 auto',
    width: 'auto',
  },
  '& .MuiTabs-flexContainer': {
    display: 'inline-flex',
    borderRadius: '100px',
    backgroundColor: 'transparent',
    padding: '5px',
    position: 'relative',
    zIndex: 1,
  },
  '& .MuiTabs-indicator': {
    height: 'calc(100% - 10px)',
    backgroundColor: theme.palette[color || 'neutral'].contrastText,
    borderRadius: '25px',
    top: 0,
    bottom: 0,
    margin: 'auto 0',
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

export const ToggleTab = styled(Tab)<ToggleTabProps>(({ theme, color }) => ({
  backgroundColor: 'transparent',
  borderRadius: '100px',
  color: theme.palette[color || 'neutral'].contrastText,
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: '500',
  minHeight: '36px',
  transition: 'color 0.4s ease',
  '&.Mui-selected': {
    color: theme.palette[color || 'neutral'].dark,
  },
  '@media (max-width: 540px)': {
    fontSize: '12px',
  },
}));
