import type React from 'react';
import classNames from 'classnames';

type TooltipProps = {
  id?: string;
  showCond: boolean;
  children?: React.ReactNode;
}

const TdTooltip: React.FC<TooltipProps> = ({ id, showCond, children }) => (
  <div
    id={ id }
    data-testid="tooltip"
    className={ classNames(
      {
        'show': showCond,
      },
      'tooltip-td | top bs-tooltip-top',
    ) }>
    <span className="tooltip-arrow" />
    <span className="tooltip-inner" data-testid="tooltip-content">{ children }</span>
  </div>
);

export default TdTooltip;
