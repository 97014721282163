import type { FC } from 'react';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent } from '@storyblok/react';

import TdLink from '@/components/uikit/link/TdLink';
import type { ImageIconLinkStoryblok } from '@/sb-types';


type ImageIconLinkBlokProps = {
  blok: SbBlokData<ImageIconLinkStoryblok>;
}

export const ImageIconLinkBlok: FC<ImageIconLinkBlokProps> = ({ blok }) => (
  <TdLink href={ blok.link?.cached_url || '' } target={ blok.target }>
    {
      blok.icon?.map(icon => (
        <StoryblokComponent blok={ icon } key={ icon._uid } />)) }
    {
      blok.image?.map(image => (
        <StoryblokComponent blok={ image } key={ image._uid } />)) }
  </TdLink>
);
