import type { AxiosResponse } from 'axios';
import axios from 'axios';
import FormData from 'form-data';

import { getTdcDomain } from '@/utils/cookies.util';

import type { SignupData, Td2DataResponse, TdcDataResponse } from '../types';

function signupTdc(email: string): Promise<AxiosResponse<TdcDataResponse>> {
  const tdClassicApi =`https://${ getTdcDomain() }/check_email`;

  const bodyData = new FormData();
  bodyData.append('email', email);
  return axios.post(tdClassicApi, bodyData);
}

function signupTd2(data: SignupData): Promise<AxiosResponse<Td2DataResponse>> {
  const td2Api = `${ process.env.NEXT_PUBLIC_API_HOST }/register/signup`;

  return axios.post(td2Api, data);
}

const signupApi = {
  signupTdc,
  signupTd2,
};

export default signupApi;
