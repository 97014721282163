import type { FC } from 'react';
import styled from '@emotion/styled';
import MuiGrid from '@mui/material/Unstable_Grid2';
import type { Breakpoint } from '@mui/system/createTheme';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import type { GridStoryblok } from '@/sb-types';

import { strToNumber } from '../../../storyblok/utils';


type GridBlokProps = {
  blok: SbBlokData<GridStoryblok>;
}

const StyledGrid = styled(MuiGrid)<GridStoryblok>(({ theme, sizeObj, marginObj, paddingObj, config }) => ({
  ...config,
  ...sizeObj,
  ...marginObj,
  ...paddingObj,

  ...theme.utils.objStyleBreakpoints(config, sizeObj, marginObj, paddingObj),
}));

export const GridBlok: FC<GridBlokProps> = ({ blok }) => (
  <StyledGrid
    container
    columnSpacing={ blok.spacingObj?.columnSpacing }
    rowSpacing={ blok.spacingObj?.rowSpacing || '0px' }
    columns={{ ...getColumns(blok) }}
    { ...storyblokEditable(blok) }
    { ...blok }
  >
    {
      blok.gridItems.map(columnBlok => (<StoryblokComponent blok={ columnBlok } key={ columnBlok._uid } />))
    }
  </StyledGrid>
);

const getColumns = (blok: SbBlokData<GridStoryblok>): {
  [key in Breakpoint]?: number | null;
} => ({
  zero: strToNumber(blok.xsColumns),
  sm: strToNumber(blok.smColumns),
  md: strToNumber(blok.mdColumns),
  lg: strToNumber(blok.lgColumns),
  xl: strToNumber(blok.xlColumns),
});
