import type React from 'react';
import { css } from '@emotion/react';
import classNames from 'classnames';
import type { Property } from 'csstype';
import type { ImageProps } from 'next/image';
import Image from 'next/image';

import styles from './TdImage.module.scss';

export type TdImageProps = {
  /** Defines the corner that won't the border radius. By default, this is `undefined` */
  noBorderCorner?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  /** Border radius that will be applied to the card. By default, this is 25px  */
  borderRadius?: Property.BorderRadius;
  background?: Property.Background;
  padding?: Property.Padding;
  disableShadow?: boolean;
} & ImageProps

const TdImage: React.FC<TdImageProps> = (
  {
    alt,
    borderRadius = '25px',
    className,
    disableShadow,
    noBorderCorner,
    style,
    ...allImgProps
  },
) => <Image
  alt={ alt }
  className={ classNames(styles.TdImage, className) }
  css={ css({
    borderRadius,
    '--td-image-box-shadow': disableShadow ? 'none' : undefined,
    ...(noBorderCorner === 'bottomLeft' && { borderBottomLeftRadius: 0 }),
    ...(noBorderCorner === 'bottomRight' && { borderBottomRightRadius: 0 }),
    ...(noBorderCorner === 'topLeft' && { borderTopLeftRadius: 0 }),
    ...(noBorderCorner === 'topRight' && { borderTopRightRadius: 0 }),
    ...style,
  }) }
  { ...allImgProps }
/>;

export default TdImage;
