import type React from 'react';

import type { TdInputProps } from '@/components/uikit/Input';
import { TdBoxInputPassword } from '@/components/uikit/Input/PrivateComponents/TdBoxInputPassword';
import { TdFormControlPassword } from '@/components/uikit/Input/PrivateComponents/TdFormControlPassword';

export const TdInputPassword: React.FC<TdInputProps> = (props) => {
  const { containerType } = props;
  return containerType === 'InputBox' ? <TdBoxInputPassword { ...props } /> : <TdFormControlPassword { ...props } />;
};
