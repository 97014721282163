import type { FC } from 'react';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { RichTextStoryblok } from '@/sb-types';

import { renderRichText } from '../../../storyblok/render-rich-text';


type RichTextBlokProps = {
  blok: SbBlokData<RichTextStoryblok>;
}

export const RichTextBlok: FC<RichTextBlokProps> = ({ blok }) => (
  <div { ...storyblokEditable(blok) }>
    { renderRichText(blok.content, {
      paragraphConfig: blok.paragraphConfig,
      paragraphOptions: blok.paragraphOptions,
      variant: blok.paragraphVariant,
      linkColorTest: blok.linkColorTest,
    }) }
  </div>
);
