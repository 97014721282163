import type { FC } from 'react';
import Typography from '@mui/material/Typography';
import type { SbBlokData } from '@storyblok/js';
import classNames from 'classnames';
import { className } from 'postcss-selector-parser';

import TdLink from '@/components/uikit/link/TdLink';
import type { NavItemStoryblok } from '@/sb-types';
import styles from '@/shared/nav-item/NavItem.module.scss';


type NavItemBlok = {
  blok: SbBlokData<NavItemStoryblok>;
}

export const NavItemBlok: FC<NavItemBlok> = ({ blok }) => (
  <li role="none">
    <TdLink
      href={ blok.href?.cached_url }
      role="menuitem"
      className={ classNames(className, styles.NavItem) }
      aria-label={ blok.itemName }
    >
      <Typography
        mb={ 1 } lineHeight="18px"
        className={ styles.NavItem__ItemName }>
        { blok.itemName }
      </Typography>
    </TdLink>
  </li>
);
