import type React from 'react';
import { useEffect, useState } from 'react';
import type { CardContentProps } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import type { Property } from 'csstype';

type BasicCardProps = {
  background?: Property.Background;
  borderRadius?: Property.BorderRadius<string | number>;
  className?: string;
  contentClass?: string;
  contentPadding?: Property.Padding | number;
  maxWidth?: number;
  noBorderCorner?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  shadowStyle?: 'none' | 'default';
} & CardContentProps

const BasicCard: React.FC<BasicCardProps> = (
  {
    shadowStyle = 'none',
    background = 'transparent',
    borderRadius = 0,
    maxWidth,
    contentPadding,
    contentClass,
    className,
    noBorderCorner,
    children,
  },
) => {
  const defaultBoxShadow = '0px 0px 50px rgba(44, 46, 60, 0.15)';
  const [boxShadow, setBoxShadow] = useState<Property.BoxShadow>('none');

  useEffect(() => {
    if (shadowStyle === 'default') setBoxShadow(defaultBoxShadow);
  }, [shadowStyle]);

  return (
    <Card
      className={ className }
      sx={
        {
          background,
          borderRadius,
          boxShadow,
          maxWidth,
          ...(noBorderCorner === 'bottomLeft' && { borderBottomLeftRadius: 0 }),
          ...(noBorderCorner === 'bottomRight' && { borderBottomRightRadius: 0 }),
          ...(noBorderCorner === 'topLeft' && { borderTopLeftRadius: 0 }),
          ...(noBorderCorner === 'topRight' && { borderTopRightRadius: 0 }),
        }
      }>
      <CardContent
        className={ contentClass }
        sx={{
          height: '100%',
          padding: contentPadding, ':last-child': {
            paddingBottom: 'unset',
          },
        }}
      >
        { children }
      </CardContent>
      <CardContent sx={{ padding: '0 !important' }} />
    </Card>
  );
};

export default BasicCard;
