import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import type { PricingCardStoryblok } from '@/sb-types';


type PricingCardBlokProps = {
  blok: SbBlokData<PricingCardStoryblok>
}

const pricingCardStyle = {
  Root: css`
    width: 100%;
    height: 25px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;

    @media (max-width: 1023px) {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }

    @media (max-width: 575px) {
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
    }

  `,
}

const PricingCard = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})<PricingCardStoryblok>(
  ({ paddingObj, marginObj, sizeObj, theme, color, borderObj }) => ({

    color,
    border: `2px solid ${ color }`,

    ...paddingObj,
    ...marginObj,
    ...sizeObj,
    ...borderObj,

    ...theme.utils.objStyleBreakpoints(paddingObj, marginObj, sizeObj, borderObj),
  }),
);

export const PricingCardBlok: FC<PricingCardBlokProps> = ({ blok }) => (
  <PricingCard
    { ...blok } { ...storyblokEditable(blok) }>
    <div css={ pricingCardStyle.Root } style={{ backgroundColor: `${ blok.color }` }} />
    {
      blok.content?.map((nestedBlok) => (<StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
    }
  </PricingCard>
);

