export function buildQueryString(query: Record<string, string>) {
  const params = new URLSearchParams(query);
  return params.toString() ? `?${ params.toString() }` : '';
}

export function allowedEmailDomain(emailAddress = '') {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') return true;

  const tdDomainsSet = new Set([
    'timedoctorinviteonly.com',
    'timedoctordev.com',
    'timedoctor.com',
    'timedoctortest.com',
    'timedoctor.dev',
    'staff.com',
    'staff.dev',
    'inbound.devmail.email',
  ]);

  const emailDomain = emailAddress?.split('@')?.pop() || '';

  return tdDomainsSet.has(emailDomain);
}
