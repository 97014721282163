import { isValidPhoneNumber } from 'react-phone-number-input';
import type { AnySchema } from 'yup';
import * as Yup from 'yup';

import { NotTDEmailMessage } from '@/components/uikit/Input/PrivateComponents/NonTDAccountsMsg';
import type { SignUpFormData } from '@/types/signup.types';
import { allowedEmailDomain } from '@/utils/common.util';
import { PasswordRuleManager } from '@/utils/password-rule-strategy.util';


type SignupFormSchema = {
  [key in keyof SignUpFormData]: AnySchema;
}

export const passwordErrorMsg = 'Enter a password of at least 8 characters, including at least one uppercase letter, one lowercase letter, one number and one special character. Spaces are not allowed.';
export const password256Max = 'Password can\'t exceed the maximum limit of 256 characters';

const emailErrorMsg = 'Please enter a valid email address!';
const nameErrorMsg = 'Oops! Name field should not be blank.';
const namePatternError = 'Your name can be a maximum of 32 characters. Allowed characters are: letters, apostrophe, dash, and period.';
const companyRequiredMsg = 'Please select your company size!';
const phoneErrorMsg = 'Please enter a valid phone number!';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(nameErrorMsg)
    .matches(
      /^[A-Za-z '-.]+$/g,
      namePatternError,
    ),
  email: Yup.string()
    .required(emailErrorMsg)
    .email(emailErrorMsg)
    .test('Validate Email', NotTDEmailMessage, (value) => allowedEmailDomain(value)),
  password: Yup.string()
    .required(passwordErrorMsg)
    .min(8, passwordErrorMsg)
    .max(256, password256Max)
    .matches(/[A-Z]+/g, passwordErrorMsg)
    .matches(/[a-z]+/g, passwordErrorMsg)
    .matches(/\d+/g, passwordErrorMsg)
    .matches(/[\W_]+/g, passwordErrorMsg),
});


const passwordRules = PasswordRuleManager.rules();

const validatePasswordRules = (value: string | undefined): boolean => passwordRules.every(rule => rule.validate(value || ''));

export const getPasswordErrorMsgs = (data: { value: string }): string => {
  const failedRules = passwordRules.filter(rule => !rule.validate(data.value));
  return failedRules.map(rule => rule.label).join(' : ');
};

export const validationSchemaWeb3 = Yup.object().shape<SignupFormSchema>({
  name: Yup.string()
    .required(nameErrorMsg)
    .matches(
      /^[A-Za-z '-.]+$/g,
      namePatternError,
    ),
  email: Yup.string()
    .required(emailErrorMsg)
    .email(emailErrorMsg)
    .test('Validate Email', NotTDEmailMessage, (value )=> allowedEmailDomain(value)),
  password: Yup.string()
    .test('password', getPasswordErrorMsgs, validatePasswordRules),
  companySize: Yup.string()
    .required(companyRequiredMsg),
  phoneNumber: Yup.string()
    .test('valid phone', phoneErrorMsg, (value) => {
      if (value == null) return true;
      return isValidPhoneNumber(value);
    }),
});
