import type { FC } from 'react';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { LinkTrackingCtx } from '@/components/uikit/link/TdLink';
import type { NavColumnStoryblok } from '@/sb-types';
import styles from '@/shared/nav-tab/NavTab.module.scss';

type NavColumnBlokProps = {
  blok: SbBlokData<NavColumnStoryblok>;
}

export const NavColumnBlok: FC<NavColumnBlokProps> = ({ blok }) => (
  <LinkTrackingCtx.Provider
    key={ `nav-column-${ blok.title }` }
    value={{ location: 'topnav', category: `TopNav-${ blok.title }` }}>
    <div
      { ...blok } { ...storyblokEditable(blok) }
      className={ styles.NavTabBox__NavColumn } role="group">
      <h6
        className={ styles.NavTabBox__NavColumn__Title }
      >
        { blok.title }
      </h6>
      <ul role="menu">
        { blok?.navItem?.map(nestedBlok => (
          <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />
        )) }
      </ul>
    </div>
  </LinkTrackingCtx.Provider>
);
