import type { Theme } from '@mui/material/styles';

import { theme } from './index';

export const utils: Theme['utils'] = {
  spacing: (spacing) => {
    if (typeof spacing==='number') {
      return `${ spacing * 8 }px`;
    }

    return spacing;
  },
  objStyleBreakpoints: (...styleObj) => styleObj.reduce((
    acc: Record<string, Record<string, string | number | undefined>>,
    obj,
  ) => ({
    [theme.breakpoints.down('xl')]: {
      ...acc?.[theme.breakpoints.down('xl')],
      ...obj?.xl,
    },
    [theme.breakpoints.down('lg')]: {
      ...acc?.[theme.breakpoints.down('lg')],
      ...obj?.lg,
    },
    [theme.breakpoints.down('md')]: {
      ...acc?.[theme.breakpoints.down('md')],
      ...obj?.md,
    },
    [theme.breakpoints.down('sm')]: {
      ...acc?.[theme.breakpoints.down('sm')],
      ...obj?.sm,
    },
  }), {}),
}
