import type { PlanId } from '../features/pricing-plans/types';

export type PricingPlansState = {
  usersCount: number;
  planType: PricingPlan;
}

export type HomeCtaPricingPlans = {
  'rr-book-launch': PricingPlan,
  TimeDoctorPartner10: PricingPlan,
  TimeDoctorPartner20: PricingPlan,
  TimeDoctorPartner50: PricingPlan,
  TDDeelPromo10: PricingPlan,
  'pg-pricing-hero-free_forever': PricingPlan,
  'pg-pricing-hero-basic': PricingPlan,
  'pg-pricing-hero-standard': PricingPlan,
  'pg-pricing-hero-premium': PricingPlan,
  'pg-pricing-features-basic': PricingPlan,
  'pg-pricing-features-standard': PricingPlan,
  'pg-pricing-features-premium': PricingPlan,
}

export const homeCtaPricingPlans: HomeCtaPricingPlans = {
  'rr-book-launch': 'standard_60',
  TimeDoctorPartner10: 'standard_plan',
  TimeDoctorPartner20: 'standard_plan',
  TimeDoctorPartner50: 'standard_plan',
  TDDeelPromo10: 'standard_plan',
  'pg-pricing-hero-free_forever': 'free_forever',
  'pg-pricing-hero-basic': 'basic_plan',
  'pg-pricing-hero-standard': 'standard_plan',
  'pg-pricing-hero-premium': 'premium_plan',
  'pg-pricing-features-basic': 'basic_plan',
  'pg-pricing-features-standard': 'standard_plan',
  'pg-pricing-features-premium': 'premium_plan',
};


export const paddlePricingPlans: Record<PaddlePricingPlan, PlanId> = {
  'basic_plan': 'basic_monthly',
  'basic_plan_annual': 'basic_yearly',
  'standard_plan': 'standard_monthly_01',
  'standard_plan_annual': 'standard_yearly_01',
  'premium_plan': 'premium_monthly',
  'premium_plan_annual': 'premium_yearly',
};

export type PricingPlan = 'free_forever' | 'basic_plan' | 'standard_plan' | 'premium_plan' | 'basic_plan_annual' |
  'standard_plan_annual' | 'premium_plan_annual' | 'standard_60';

export type PaddlePricingPlan = Extract<PricingPlan, 'basic_plan' | 'basic_plan_annual' | 'standard_plan' | 'standard_plan_annual' | 'premium_plan' | 'premium_plan_annual'>

export const pricingTypeMap: Map<PricingPlan, number> = new Map([
  ['basic_plan', 1],
  ['standard_plan', 2],
  ['premium_plan', 3],
  ['basic_plan_annual', 1],
  ['standard_plan_annual', 2],
  ['premium_plan_annual', 3],
]);

export const defaultPricingPlan: PaddlePricingPlan = 'premium_plan';
