import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import trackingUtils from '@/utils/tracking.util';

import type { AnalyticsPagePayload, AnalyticsTrackPayload, TrackingPageInfo, TrackingState } from '../../types';

const initialState: TrackingState = {
  signupForm: {
    engagementLocations: [],
  },
  page: '',
  signupTrigger: 'main-form',
  pageInfo: {
    name: '',
    category: '',
  },
};

export const analyticsTrack = createAsyncThunk(
  'analyticsTrack',
  async (trackingData: AnalyticsTrackPayload) => trackingUtils.trackEvent(trackingData),
);

export const analyticsPage = createAsyncThunk(
  'analyticsPage',
  async (trackingData: AnalyticsPagePayload) => trackingUtils.trackPage(trackingData),
);

const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    reset: () => initialState,
    pageInfo: (state, action: PayloadAction<TrackingPageInfo>) => {
      state.pageInfo = action.payload;
    },
    // ToDo: Remove this legacy page in favor of pageInfo
    page: (state, action: PayloadAction<string>) => {
      state.page = action.payload;
    },
    signupFormEngaged: (state, action: PayloadAction<string>) => {
      state.signupForm.engagementLocations.push(action.payload);
    },
    signupTrigger: (state, action: PayloadAction<string>) => {
      state.signupTrigger = action.payload;
    },
    resetSignupTrigger: state => {
      state.signupTrigger = 'main-form';
    },
  },
  extraReducers: builder => {
    builder.addCase(analyticsTrack.fulfilled, () => undefined);
    builder.addCase(analyticsPage.fulfilled, () => undefined);
  },
});

export const {
  signupFormEngaged,
  page,
  pageInfo,
  reset,
  signupTrigger,
  resetSignupTrigger,
} = trackingSlice.actions;

export const trackingThunks = {
  analyticsTrack,
  analyticsPage,
};
export default trackingSlice.reducer;
