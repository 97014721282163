import type { FC } from 'react';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import ReviewBadge from '@/components/uikit/review-badge/ReviewBadge';
import type { ReviewBadgeStoryblok } from '@/sb-types';

import CapterraLogo from '../../../../public/images/logos/capterra-logo.svg';
import G2Logo from '../../../../public/images/logos/g2-logo.svg';
import GetAppLogo from '../../../../public/images/logos/getapp-logo.svg';


type AvailableReviewLogo = 'capterra' | 'g2' | 'getapp';

type ReviewBadgeBlokProps = {
    blok: SbBlokData<ReviewBadgeStoryblok>;
}

export const ReviewBadgeBlok: FC<ReviewBadgeBlokProps> = ({ blok }) => (
  <ReviewBadge
    starColor={ blok.starColor?.color }
    stars={ blok.stars }
    badgeColor={ blok.badgeColor?.color }
    { ...storyblokEditable(blok) }
  >
    <ReviewLogo logo={ blok.reviewPage || 'capterra' } />
  </ReviewBadge>
);

const ReviewLogo: FC<{ logo: AvailableReviewLogo }> = ({ logo }) => {
  switch (logo) {
    case 'capterra':
      return <CapterraLogo />;
    case 'g2':
      return <G2Logo />;
    case 'getapp':
      return <GetAppLogo />;
  }
};
