import type { FC } from 'react';
import { useCallback } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeProvider } from '@mui/material/styles';
import MuiTypography from '@mui/material/Typography';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { styles } from '@/components/sb-bloks/predefined-sections/HeroSectionBlok/HeroSectionBlok.styles';
import { FlexBoxV2 } from '@/components/uikit/flex-box/FlexBox';
import type { HeroSectionStoryblok } from '@/sb-types';

import { web3Theme } from '../../../../theme';


type HeroSectionBlokProps = {
  blok: SbBlokData<HeroSectionStoryblok>
}


const LeftColumn = styled(FlexBoxV2)<HeroSectionStoryblok>(({ leftColSize, leftColConfig, leftColGap, theme }) => css({
  flex: '1 0 auto',
  ...leftColSize,
  ...leftColGap,
  ...leftColConfig,
  ...theme.utils.objStyleBreakpoints(leftColSize, leftColGap, leftColConfig),
}));

const RightColumn = styled.div<HeroSectionStoryblok>(({ theme, rightColSize }) => css`
  padding-top: var(--hero-section-right-clm-pt, 0);
  flex: 0 1 auto;
  ${ { ...rightColSize } };

  ${ { ...theme.utils.objStyleBreakpoints(rightColSize) } };

  ${ theme.breakpoints.down('sm') } {
    flex: 1 auto;
  }
`);

const StyledFlexBox = styled(FlexBoxV2)<HeroSectionStoryblok>(({ theme, gapObj, flexConfig }) => css({
  ...flexConfig,
  ...gapObj,
  ...theme.utils.objStyleBreakpoints(gapObj, flexConfig),
}));

export const HeroSectionBlok: FC<HeroSectionBlokProps> = ({ blok }) => (
  <ThemeProvider theme={ web3Theme }>
    <StyledFlexBox alignItems="center" lgWrap { ...blok } { ...storyblokEditable(blok) }>
      <LeftColumn column lgFlex="1 100%" { ...blok }>
        <MuiTypography variant="h4" component="h1" color="primary.main" className="HeroSec-Title">
          { blok.titleSection }
        </MuiTypography>
        <MuiTypography variant="h1" component="h2" className="HeroSec-SubTitle" css={ styles.Subtitle }>
          { blok.subtitle }
          { blok.animatedTitle?.length ? <AnimatedHeading animatedTitle={ blok.animatedTitle } /> : null }
        </MuiTypography>
        <MuiTypography variant="body1" color="info.main" className="HeroSec-Desc">
          { blok.description }
        </MuiTypography>

        <FlexBoxV2 columnGap={ 4.5 } renderIf={ !!blok.buttons?.length }>
          { blok.buttons?.map((buttonBlok, index) => (<StoryblokComponent blok={ buttonBlok } key={ index } />)) }
        </FlexBoxV2>

        <FlexBoxV2 columnGap={ 4.5 } smColumnGap={ 2 } renderIf={ !!blok.reviews?.length }>
          { blok.reviews?.map((reviewBadgeBlok, index) => (<StoryblokComponent blok={ reviewBadgeBlok } key={ index } />)) }
        </FlexBoxV2>
      </LeftColumn>

      <RightColumn className="HeroSection-RightCol" { ...blok }>
        { blok.media?.map((mediaBlok, index) => (<StoryblokComponent blok={ mediaBlok } key={ index } />)) }
      </RightColumn>
    </StyledFlexBox>
  </ThemeProvider>
);

const AnimatedHeading: FC<Pick<HeroSectionStoryblok, 'animatedTitle'>> = ({ animatedTitle }) => {
  const onH2Loaded = useCallback((node: HTMLElement | null) => {
    if (node != null) {
      const allSpans = node.querySelectorAll('span');

      if (allSpans.length) {
        setInterval(() => {
          for (const [i, span] of allSpans.entries()) {
            if (span.getAttribute('data-displayed') === 'true') {
              const nextSpan = allSpans.length - 1 === i ? 0 : i + 1;
              span.setAttribute('data-displayed', 'false');
              allSpans[nextSpan].setAttribute('data-displayed', 'true');

              break;
            }
          }
        }, 5000);
      }
    }
  }, []);

  return (
    <span ref={ onH2Loaded } css={ styles.AnimatedHeading }>
      &nbsp;
      {
        animatedTitle?.map((text, index) => (
          <span key={ index } data-displayed={ index === 0 }>{ text }</span>
        ))
      }
    </span>
  );
};
