import type { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MuiTypography from '@mui/material/Typography';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { FlexBoxV2 } from '@/components/uikit/flex-box/FlexBox';
import type { ArticleWithMediaStoryblok, ImageStoryblok } from '@/sb-types';
import RenderIf from '@/shared/render-if/RenderIf';

import { renderRichText } from '../../../storyblok/render-rich-text';

type ArticleWithMediaBlokProps = {
  blok: SbBlokData<ArticleWithMediaStoryblok>;
}

type VariantWithImageProps = {
  imageBlok: ImageStoryblok;
}

type VariantWithVideoProps = {
  videoUrl: string;
}

const styles = {
  Root: css`
    position: relative;
    width: 100%;
  `,
  Img: css`
    width: 100%;
    height: auto;
  `,
  Video: css`
    width: 100%;
    aspect-ratio: 16 / 9;

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  `,
};

const LeftColumn = styled.div<ArticleWithMediaStoryblok>(({ leftColumnSize, columnOrder, theme }) => ({
  width: '50%',
  order: columnOrder === 'inverted' ? 1 : 0,
  ...leftColumnSize,
  ...theme.utils.objStyleBreakpoints(leftColumnSize),
}));

const RightColumn = styled.div<ArticleWithMediaStoryblok>(({ rightColumnSize, theme }) => ({
  width: '45%',

  ...rightColumnSize,
  ...theme.utils.objStyleBreakpoints(rightColumnSize),
}));

const StyledFlexBox = styled(FlexBoxV2)<ArticleWithMediaStoryblok>(
  ({ theme, configObj, marginObj, paddingObj, gapObj }) => css({
    ...marginObj,
    ...paddingObj,
    ...gapObj,
    ...configObj,

    ...theme.utils.objStyleBreakpoints(marginObj, paddingObj, gapObj, configObj),
  }),
)

export const ArticleWithMediaBlok: FC<ArticleWithMediaBlokProps> = ({ blok }) =>
  (
    <StyledFlexBox
      as="article" css={ styles.Root }
      justifyContent="space-between" alignItems="flex-start" rowGap={ 7 }
      { ...storyblokEditable(blok) }
      { ...blok }
    >
      <LeftColumn { ...blok }>
        <RenderIf condition={ !!blok.title }>
          <MuiTypography variant="subtitle2" component="h4" color="primary" mb={ 1 }>
            { blok.title }
          </MuiTypography>
        </RenderIf>

        { renderRichText(blok.content) }

        { blok.cta?.map(nestedBlok => <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />) }
      </LeftColumn>

      <RightColumn { ...blok }>
        { (blok.variant === 'image' && blok.image?.length) ? <VariantWithImage imageBlok={ blok.image[0] } /> : null }
        { (blok.variant === 'video' && blok.videoUrl?.url) ? <VariantWithVideo videoUrl={ blok.videoUrl.url } /> : null }
      </RightColumn>
    </StyledFlexBox>
  )
;


const VariantWithImage: FC<VariantWithImageProps> = ({ imageBlok }) => (
  <StoryblokComponent blok={ imageBlok } />
);

const VariantWithVideo: FC<VariantWithVideoProps> = ({ videoUrl }) => (
  <div css={ styles.Video }>
    <iframe
      src={ videoUrl }
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen />
  </div>
);
