import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import pricingPlanReducer from './features/pricingPlanSlice';
import signupReducer from './features/signupSlice';
import trackingReducer from './features/trackingSlice';

export const store = configureStore({
  reducer: {
    signup: signupReducer,
    pricingPlan: pricingPlanReducer,
    tracking: trackingReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const wrapper = createWrapper(() => store, { debug: false });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
