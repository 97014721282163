type CookieOptions = {
  expires?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
}

/**
 * Gets a cookie
 *
 * @param name The name of the cookie to retrieve.
 * @returns a string that represents the cookie value, or null when it is empty or
 * does not exist.
 */
export function getCookie(name: string) {
  const re = new RegExp(name + '=([^;]+)');
  const value = re.exec(document.cookie);
  return value != null ? decodeURIComponent(value[1]) : '';
}

/**
 * Sets a cookie based on a given name, value and additional options.
 *
 * @param name The name of the cookie to set
 * @param value The value of the new cookie.
 * @param options An object literal containing key/value pairs to provide optional cookie
 * attributes.
 * @param options.expires Either an integer specifying the expiration date from now on
 * in days.
 * @param options.path The value of the path attribute of the cookie (default: path of
 * page that created the cookie).
 * @param options.domain The value of the domain attribute of the cookie (default: domain
 * of page that created the cookie).
 * @param options.secure If true, the secure attribute of the cookie will be set and the
 * cookie transmission will require a secure protocol (like HTTPS).
 */
export function setCookie(name: string, value: string | null, options: CookieOptions = {}) {
  const domainArr = window.location.hostname.split('.');

  let domain = domainArr.length > 2
    ? domainArr[domainArr.length - 2] + '.' + domainArr[domainArr.length - 1]
    : window.location.hostname;
  domain = domain === 'vercel.app' ? window.location.hostname : domain;

  let expire = Date.now() + 3600 * 1000 * 24 * 365 * 10;

  if (options.expires) {
    expire = Date.now() + (options.expires * 24 * 60 * 60 * 1000);
  }

  const nameValStr = `${ name }=${ encodeURIComponent(value || '') };`;
  const domainStr = `Domain=${ options.domain || domain };`;
  const pathStr = `Path=${ options.path || '/' };`;
  const expireStr = `Expires=${ new Date(expire).toUTCString() };`;
  const secureStr = `${ options.secure ? 'Secure' : '' }`;

  document.cookie = [nameValStr, domainStr, pathStr, expireStr, secureStr].join('');
}

export function parseCookieObj(cookieVal: string): Record<string, unknown> | null {
  if (!cookieVal) return null;

  try {
    const tempCookieValue = cookieVal.replace((/([\w]+)(:)/g), '"$1"$2');
    const parsedCookieValue = tempCookieValue.replace((/'/g), '"');

    return JSON.parse(parsedCookieValue);
  } catch (e) {
    return null;
  }
}

export function getMainDomain() {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'timedoctor.com'
    : 'timedoctortest.com';
}

export function getTdcDomain() {
  return process.env.NEXT_PUBLIC_TDC_MAIN_DOMAIN || 'login.timedoctor.com';
}

export function deleteUTMCookies() {
  const urlParams = new URLSearchParams(window.location.search);
  const UTMCookies: Array<string> = ['utm_source', 'utm_medium', 'utm_term', 'utm_content', 'utm_campaign'];
  const queryParams = Array.from(urlParams.keys());
  if(!queryParams.some(utm => UTMCookies.includes(utm))) return;
  UTMCookies.forEach((cookie) => {
    if(!queryParams.includes(cookie)) {
      document.cookie = `${ cookie }=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=.${ document.domain?.split('.').splice(1).join('.') }`;
    }
  })
}

const cookieUtilities = {
  getCookie,
  setCookie,
}

export default cookieUtilities;
