import type { FC } from 'react';
import type { SbBlokData } from '@storyblok/js';

import { HubspotForm } from '@/components/web3/HubspotForm';
import type { HubspotFormStoryblok } from '@/sb-types';
import RenderIf from '@/shared/render-if/RenderIf';


type HubspotFormBlokProps = {
  blok: SbBlokData<HubspotFormStoryblok>
}

export const HubspotFormBlok: FC<HubspotFormBlokProps> = ({ blok }) => (
  <RenderIf condition={ !!blok.formId }>
    <HubspotForm
      formId={ blok.formId }
      formCategory={ blok.formCategory }
      trackEventName={ blok.trackingEventName }
      redirectUrl={ blok.redirectUrl?.url }
      withLoader={ blok.withLoader }
    />
  </RenderIf>
);
