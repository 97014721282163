import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const styles = {
  Root: (theme: Theme) => css`
    --gutter: 24px;
    --header-padding: 12px var(--gutter);
    --accordion-icon-color: var(--color-primary-accent);
    --accordion-expanded-icon-rotation: 180deg;
    --accordion-expanded-bg-color: #fff;

    overflow: hidden;
    transition: height 0.35s ease-in-out;

    &[data-variant='large'] {
      --gutter: 40px;
    }

    ${ theme.breakpoints.down('sm') } {
      &, &[data-variant='large'] {
        --gutter: 8px;
      }
    }
  `,
  Header: css`
    padding: 2px;

    [data-variant='large'][data-expanded='true'] & {
      background-color: var(--accordion-expanded-bg-color);
    }
  `,
  Trigger: css`
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 8px;

    margin: 0;
    padding: 15px 0;
    width: 100%;

    text-align: left;
    background: none;
    border: 0;
    border-radius: 4px;

    &:focus-visible {
      outline: 2px solid var(--color-secondary-jungleblack);
      border-radius: 4px;
    }

    &[aria-expanded='true'] {
      background-color: var(--accordion-expanded-bg-color);
    }

    [data-icon-position='before'] & {
      flex-direction: row-reverse;
    }

    [data-variant='large'] & {
      justify-content: space-between;
      border-radius: 0;
    }
  `,
  Title: css`
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;

    flex-grow: 1;
    color: '#342B27'
  `,
  Icon: css`
    color: var(--accordion-icon-color);
    transition: all 0.3s ease;

    [aria-expanded='true'] & {
      transform: rotate(var(--accordion-expanded-icon-rotation));
    }
  `,
  Content: css`
    padding-bottom: 15px;

    [data-variant='large'][data-expanded='true'] & {
      background-color: var(--accordion-expanded-bg-color);
    }
  `,
  PlusIcon: css`
    display: inline-block;
    position: relative;
    width: 22px;
    height: 2px;
  `,
  PlusIcon__Line: (theme: Theme) => css`
    display: inline-block;
    position: absolute;
    left: 0;

    width: 22px;
    height: 2px;
    background-color: var(--accordion-icon-color);
    transition: transform .25s ease-in;

    &:first-of-type {
      transform-origin: center;
      transform: rotate(90deg);

      [aria-expanded='true'] & {
        transform: rotate(0deg);
      }
    }

    ${ theme.breakpoints.down('sm') } {
      width: 16px;
    }
  `,
};
