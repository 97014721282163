import { useEffect } from 'react';
import { initializePaddle } from '@paddle/paddle-js';
import * as Sentry from '@sentry/browser';

export const usePaddleInit = () => {
  useEffect(() => {
    // Only initialize Paddle on the home page
    if (window.location.pathname !== '/') return;
    const paddleEnvironment = process.env.NEXT_PUBLIC_PADDLE_ENVIRONMENT || 'production';

    initializePaddle({
      token: process.env.NEXT_PUBLIC_PADDLE_TOKEN || '',
      pwAuth: process.env.NEXT_PUBLIC_PADDLE_PW_AUTH || '',
      environment: paddleEnvironment as 'sandbox' | 'production',
    }).then()
      .catch((error) => {
        Sentry.captureMessage('Paddle initialization failed', {
          extra: {
            error,
            level: 'warning',
          },
        });
      });
  }, []);
};
