import type { FC } from 'react';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InputAdornment from '@mui/material/InputAdornment';

import { FormInputCtx } from '@/components/uikit/Input/TdInput';
import type { SignUpFormData } from '@/types/signup.types';

type ValidityAdornmentProps = {
  variety: keyof SignUpFormData;
  additionalErrCond?: boolean;
}

const ValidityIcon: FC<{
    condition: boolean
}> = ({ condition }) => (condition ? <CheckCircleIcon color="primary" /> :
  <ErrorIcon color="error" />);

export const ValidityAdornment: FC<ValidityAdornmentProps> = ({ variety, additionalErrCond }) => {
  const { watch, getFieldState, formState: { errors }} = useFormContext<SignUpFormData>();
  const { touchedFields } = useContext(FormInputCtx);
  const isTouched = touchedFields[variety];
  const watchValue = watch(variety);
  const { invalid } = getFieldState(variety);

  return (
    <InputAdornment
      position="end"
      style={{
        opacity: isTouched || (!!watchValue && !invalid) ? 1 : 0,
      }}>
      <ValidityIcon condition={ !errors[variety]?.message && !additionalErrCond } />
    </InputAdornment>
  );
};
