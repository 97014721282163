import type { FC } from 'react';
import { createContext, useContext, useState } from 'react';

interface NavContextProps {
  selectedNav: string | null;
  setSelectedNav: (nav: string | null) => void;
}

const NavContext = createContext<NavContextProps | undefined>(undefined);

export const useNavContext = () => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error('useNavContext must be used within a NavProvider');
  }
  return context;
};

type NavProviderProps = {
  children: React.ReactNode;
}
export const NavProvider: FC<NavProviderProps> = ({ children }) => {
  const [selectedNav, setSelectedNav] = useState<string | null>(null);
  return (
    <NavContext.Provider value={{ selectedNav, setSelectedNav }}>
      { children }
    </NavContext.Provider>
  );
};
