import { ArticleWithMediaBlok } from '@/components/sb-bloks/predefined-sections/ArticleWithMediaBlok';
import { FeedbackBlok } from '@/components/sb-bloks/predefined-sections/FeedbackSectionBlok';
import { HeroSectionBlok } from '@/components/sb-bloks/predefined-sections/HeroSectionBlok/HeroSectionBlok';
import { HeroSectionMediaBlok } from '@/components/sb-bloks/predefined-sections/HeroSectionBlok/HeroSectionMediaBlok';
import { WhitePaperBlok } from '@/components/sb-bloks/predefined-sections/WhitePaperBlok/WhitePaperBlok';

export const predefinedSectionsBloks = {
  article_with_media: ArticleWithMediaBlok,
  hero_section: HeroSectionBlok,
  hero_section_media: HeroSectionMediaBlok,
  white_paper_section: WhitePaperBlok,
  feedback_section: FeedbackBlok,
};
