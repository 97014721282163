import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { PricingPlan, PricingPlansState } from '../../types';

const initialState: PricingPlansState = {
  usersCount: 1,
  planType: 'premium_plan',
};

const pricingPlanSlice = createSlice({
  name: 'pricingPlans',
  initialState,
  reducers: {
    updateUsersCount: (state, action: PayloadAction<number>) => {
      state.usersCount = action.payload;
    },
    updatePlanType: (state, action: PayloadAction<PricingPlan>) => {
      state.planType = action.payload;
    },
  },
});

export const { updateUsersCount, updatePlanType } = pricingPlanSlice.actions;
export default pricingPlanSlice.reducer;
